import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import './App.css';

import Menu from './components/js/Menu';
import FoodMenu from './components/js/FoodMenu';
import ThePlace from './components/js/ThePlace';
import Contact from './components/js/Contact';
import ContactInfo from './components/js/ContactInfo';

import Reservations from './components/js/Reservations';

import Item1InFoodMenu from './components/js/Item1InFoodMenu';
import Item2InFoodMenu from './components/js/Item2InFoodMenu';
import Item3InFoodMenu from './components/js/Item3InFoodMenu';
import Item4InFoodMenu from './components/js/Item4InFoodMenu';
import Item5InFoodMenu from './components/js/Item5InFoodMenu';
import Item6InFoodMenu from './components/js/Item6InFoodMenu';
import Item7InFoodMenu from './components/js/Item7InFoodMenu';
import Item8InFoodMenu from './components/js/Item8InFoodMenu';
import Item9InFoodMenu from './components/js/Item9InFoodMenu';

import Signup from './components/js/Signup';
import Login from './components/js/Login';
import Logout from './components/js/Logout';
import Reset from './components/js/Reset';
import ResetConfirm from './components/js/ResetConfirm';
import Dashboard from './components/js/Dashboard';

import Menus from './components/js/Menus';
import MenuPostres from './components/js/MenuPostres';
import MenuVinos from './components/js/MenuVinos';
import MenuVinosCopeo from './components/js/MenuVinosCopeo';
import MenuDestilados from './components/js/MenuDestilados';
import MenuCocteleria from './components/js/MenuCocteleria';

import {
  FooterElement,
  DividerLineInFooter,
  ContainerNavBarInFooter,
  NavBarItem,
  StyledLink,
  ContainerSocialNetworks,
  SocialNetworks
} from './components/js/Styles'; // Ajusta la ruta según tu estructura

function NavBar(){
  return (
    <nav className="navBar">
      <div className="navBarItem" id="navBarItem1" tabIndex={1}>
        <Link to="/" className="navBarItemLink">MENÚ</Link>
      </div>
      <div className="navBarItem" id="navBarItem2" tabIndex={2}>
        <Link to="/the_place" className="navBarItemLink">EL LUGAR</Link>
      </div>
      <div className="navBarItem" id="navBarItem3" tabIndex={3}>
        <Link to="/contact" className="navBarItemLink">CONTACTO</Link>
      </div>
      <div className="navBarItem" id="navBarItem4" tabIndex={4}>
        <Link to="/reservations" className="navBarItemLink">RESERVACIONES</Link>
      </div>
    </nav>
  );
}

function Footer({navbar_type}){

  const [userLocation, setUserLocation] = useState("");
  const location = useLocation();
  const [view_active, setViewActive] = useState("");
  
  const locationList = [
    "/food_menu",
    "/the_place",
    "/contact",
    "/reservations",
    "/item_1_in_food_menu",
    "/item_2_in_food_menu",
    "/item_3_in_food_menu",
    "/item_4_in_food_menu",
    "/item_5_in_food_menu",
    "/item_6_in_food_menu",
    "/item_7_in_food_menu",
    "/item_8_in_food_menu",
    "/item_9_in_food_menu",
    "/",
    "/contact_info"
  ]

  const exteriorLocationList = [
    "https://wa.me/2225594450/",
    "https://facebook.com/profile.php?id=100087916872647",
    "https://www.instagram.com/restaurante.bardo/"
  ]

  const { latitude, longitude } = userLocation;
  const businessName = "Restaurante+Bardo";

  const googleMapsUrl = `https://www.google.com.mx/maps/place/Restaurante+Bardo/@19.0532175,-98.2296612,17z/data=!3m1!4b1!4m6!3m5!1s0x85cfc7676660739d:0x8a81dda19c3b3b13!8m2!3d19.0532175!4d-98.2270863!16s%2Fg%2F11k3h7vzdh?entry=ttu`;

  function fadeInOnScroll() {
    var elementos = document.getElementsByClassName("fade-in");

    for (var i = 0; i < elementos.length; i++) {
        var elemento = elementos[i];
        var posicion = elemento.getBoundingClientRect().top;
        var alturaVentana = window.innerHeight/1.2;
        var offset = 10; // Valor de desplazamiento adicional para controlar cuándo se debe mostrar el elemento

        if (posicion < alturaVentana - offset) {
            elemento.classList.add("visible");
            elemento.style.animation = "fadeInFromBottom 0.9s ease-in-out";
        } else {
            elemento.classList.remove("visible");
            elemento.style.animation = "";
        }
        
    }
  }

  window.addEventListener("scroll", fadeInOnScroll);

  useEffect(() => {
    fadeInOnScroll();
    // Verificar la ubicación actual y establecer la vista activa
    for (let i = 0; i < locationList.length; i++) {
      if (location.pathname === locationList[i]) {
        setViewActive(locationList[i]);
        break; // Terminar el bucle una vez que se encuentra la coincidencia
      }
    }
  }, [location.pathname]); // El arreglo vacío [] asegura que el efecto se ejecute solo una vez

  function newTab(url) {
    window.open(url, '_blank');
  }

  return(
    <FooterElement className="fade-in" plural={navbar_type === undefined ? "1" : ""}>
      {
        navbar_type ===  "1" ?
        <></>
        :
        <>
          <DividerLineInFooter />
          <ContainerNavBarInFooter>
            <NavBarItem>
              <StyledLink to={locationList[4]}>PARA EMPEZAR</StyledLink>
              {
                view_active === locationList[4] ?
                <DividerLineInFooter />
                : <></>
              }
            </NavBarItem>
            <NavBarItem>
              <StyledLink to={locationList[5]}>ENTRADAS</StyledLink>
              {
                view_active === locationList[5] ?
                <DividerLineInFooter />
                : <></>
              }
            </NavBarItem>
            <NavBarItem>
              <StyledLink to={locationList[6]}>PASTAS</StyledLink>
              {
                view_active === locationList[6] ?
                <DividerLineInFooter />
                : <></>
              }
            </NavBarItem>
            <NavBarItem>
              <StyledLink to={locationList[7]}>CARNE</StyledLink>
              {
                view_active === locationList[7] ?
                <DividerLineInFooter />
                : <></>
              }
            </NavBarItem>
            <NavBarItem>
              <StyledLink to={locationList[8]}>POSTRES</StyledLink>
              {
                view_active === locationList[8] ?
                <DividerLineInFooter />
                : <></>
              }
            </NavBarItem>
            <NavBarItem>
              <StyledLink to={locationList[9]}>DESTILADOS</StyledLink>
              {
                view_active === locationList[9] ?
                <DividerLineInFooter />
                : <></>
              }
            </NavBarItem>
            <NavBarItem>
              <StyledLink to={locationList[10]}>BEBIDAS</StyledLink>
              {
                view_active === locationList[10] ?
                <DividerLineInFooter />
                : <></>
              }
            </NavBarItem>
            <NavBarItem>
              <StyledLink to={locationList[11]}>VINOS</StyledLink>
              {
                view_active === locationList[11] ?
                <DividerLineInFooter />
                : <></>
              }
            </NavBarItem>
            <NavBarItem>
              <StyledLink to={locationList[12]}>V. COPEO</StyledLink>
              {
                view_active === locationList[12] ?
                <DividerLineInFooter />
                : <></>
              }
            </NavBarItem>
          </ContainerNavBarInFooter>
          <ContainerNavBarInFooter>
            <NavBarItem>
              <StyledLink to={locationList[13]}>MENÚ</StyledLink>
              {
                view_active === locationList[13] || view_active === locationList[0] ?
                <DividerLineInFooter />
                : <></>
              }
            </NavBarItem>
            <NavBarItem>
              <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer"className="navBarItemLink">EL LUGAR</a>
              {
                view_active === locationList[13] ?
                <DividerLineInFooter />
                : <></>
              }
            </NavBarItem>
            <NavBarItem>
              <StyledLink to={locationList[2]}>CONTACTO</StyledLink>
              {
                  view_active === locationList[2] || view_active === locationList[14] ?
                  <DividerLineInFooter />
                  : <></>
                }
            </NavBarItem>
            <NavBarItem>
              <StyledLink to={locationList[3]}>RESERVACIONES</StyledLink>
              {
                view_active === locationList[3] ?
                <DividerLineInFooter />
                : <></>
              }
            </NavBarItem>
            <NavBarItem>
              <a className="navBarItemLink">©2023 BARDO</a>     
            </NavBarItem>
          </ContainerNavBarInFooter>
          <ContainerNavBarInFooter>
            <NavBarItem>
            <a className="navBarItemLink">REDES SOCIALES</a>
            </NavBarItem>
            <ContainerSocialNetworks>
              <SocialNetworks tabIndex={8} onClick={() => newTab(exteriorLocationList[0])}>
                <div></div>
              </SocialNetworks>
              <SocialNetworks tabIndex={9} onClick={() => newTab(exteriorLocationList[1])}>
                <div></div>
              </SocialNetworks>
              <SocialNetworks tabIndex={10} onClick={() => newTab(exteriorLocationList[2])}>
                <div></div>
              </SocialNetworks>
            </ContainerSocialNetworks>
          </ContainerNavBarInFooter>
        </>
      }
    </FooterElement>
  );
}

function App() {
  const history = useHistory();
  const tokenGet = localStorage.token

  const [token, setToken] = useState("");

  const userLogin = (tok) => {

    setToken(tok)

  }

  //useEffect(() => {
    
  //  if(tokenGet != undefined || tokenGet != null){
  //   console.log("este ususario ya tiene session iniciada")
  //  }
  // else{
  //   console.log("sin session iniciada");
  //  }

  //}, []);

  return (
    <Router>
      <div className="App">
        {/* Rutas */}
        <Switch>
          <Route path="/" exact>
            <NavBar />
            <Menu />
          </Route>
          <Route path="/food_menu" exact>
            <FoodMenu />
            <Footer/>
          </Route>
          <Route path="/the_place" exact>
            <NavBar />
            <ThePlace />
          </Route>
          <Route path="/contact" exact>
            <NavBar />
            <Contact />
          </Route>
          <Route path="/contact_info" exact>
              <ContactInfo />
              <Footer />
            </Route>
          <Route path="/reservations" exact>
            <NavBar />
            <Reservations />
            <Footer />
          </Route>

          <Route path="/item_1_in_food_menu" exact>
            <NavBar />
            <Item1InFoodMenu />
            <Footer />
          </Route>
          <Route path="/item_2_in_food_menu" exact>
            <NavBar />
            <Item2InFoodMenu />
            <Footer />
          </Route>
          <Route path="/item_3_in_food_menu" exact>
            <NavBar />
            <Item3InFoodMenu />
            <Footer />
          </Route>
          <Route path="/item_4_in_food_menu" exact>
            <NavBar />
            <Item4InFoodMenu />
            <Footer />
          </Route>
          <Route path="/item_5_in_food_menu" exact>
            <NavBar />
            <Item5InFoodMenu />
            <Footer />
          </Route>
          <Route path="/item_6_in_food_menu" exact>
            <NavBar />
            <Item6InFoodMenu />
            <Footer />
          </Route>
          <Route path="/item_7_in_food_menu" exact>
            <NavBar />
            <Item7InFoodMenu />
            <Footer />
          </Route>
          <Route path="/item_8_in_food_menu" exact>
            <NavBar />
            <Item8InFoodMenu />
            <Footer />
          </Route>
          <Route path="/item_9_in_food_menu" exact>
            <NavBar />
            <Item9InFoodMenu />
            <Footer />
          </Route>
          <Route path="/signup" exact>
            <Signup />
            <Footer />
          </Route>
          <Route path="/login" exact>
            <Login userLogin={userLogin}/>
            <Footer />
          </Route>
          <Route path="/dashboard" exact>
            <Dashboard token={token} />
            <Footer />
          </Route>
          <Route path="/logout" exact>
            <Logout />
            <Footer />
          </Route>
          <Route path="/reset_password" exact>
            <Reset />
            <Footer />
          </Route>
          <Route path="/reset_password_confirm/:token" exact>
            <ResetConfirm />
            <Footer />
          </Route>

          <Route path="/menus" exact>
            <Menus />
            <Footer />
          </Route>
          <Route path="/menu_postres" exact>
            <MenuPostres />
          </Route>
          <Route path="/menu_vinos" exact>
            <MenuVinos />
          </Route>
          <Route path="/menu_vinos_copeo" exact>
            <MenuVinosCopeo />
          </Route>
          <Route path="/menu_destilados" exact>
            <MenuDestilados />
          </Route>
          <Route path="/menu_cocteleria" exact>
            <MenuCocteleria />
          </Route>
          
        </Switch>
      </div>
    </Router>
  );
}

export default App;