import { ToolbarButton } from '../../js/Styles'; // Ajusta la ruta según tu estructura

const CustomToolBar = (props) => {
    const { label, views } = props;

    // Verifica las vistas estan disponibles
    var isMonthAvailable = false;
    var isWeekAvailable = false;
    var isDayAvailable = false;
    var isAgendaAvailable = false;


    if(views[0] === "month"){
        //console.log("Si se puede ver pro mes");
        isMonthAvailable = true;
    }
    else{
        //console.log("No se puede ver por mes");
        isMonthAvailable  = false;
    }
    
    if(views[1] === "week"){
        //console.log("Si se puede ver por semana");
        isWeekAvailable = true;
    }
    else{
        //console.log("No se puede ver pro semana");
        isWeekAvailable = false
    }
    
    if(views[2] === "day"){
        //console.log("Si se puede ver por dia");
        isDayAvailable = true;
    }
    else{
        //console.log("No se puede ver por dia");
        isDayAvailable = false;
    }

    if(views[3] === "agenda"){
        //console.log("Si se puede ver la agenda");
        isAgendaAvailable = true
    }
    else{
        //console.log("No se puede ver la agenda");
        isAgendaAvailable = false;
    }

    return (
        <div className="rbc-toolbar" id='custom-tool-bar'>
            <span className="rbc-btn-group">
                <ToolbarButton type="button" onClick={() => props.onNavigate('PREV')}>
                Anterior
                </ToolbarButton>
                <ToolbarButton type="button" onClick={() => props.onNavigate('TODAY')}>
                Hoy
                </ToolbarButton>
                <ToolbarButton type="button" onClick={() => props.onNavigate('NEXT')}>
                Siguiente
                </ToolbarButton>
            </span>

            <span className="rbc-toolbar-label" style={{ textTransform: 'uppercase' }}>{label}</span>

            <span className="rbc-btn-group">
                {isMonthAvailable && (
                    <ToolbarButton type="button" onClick={() => props.onView('month')}>
                        Mes
                    </ToolbarButton>
                )}
                {isWeekAvailable && (
                    <ToolbarButton type="button" onClick={() => props.onView('week')}>
                        Semana
                    </ToolbarButton>
                )}
                {isDayAvailable && (
                    <ToolbarButton type="button" onClick={() => props.onView('day')}>
                        Día
                    </ToolbarButton>
                )}
                {isAgendaAvailable && (
                    <ToolbarButton type="button" onClick={() => props.onView('agenda')}>
                        Agenda
                    </ToolbarButton>
                )}
            
            </span>
        </div>
    );
};


export default CustomToolBar;