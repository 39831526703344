import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom"
import axios from "axios";

import AlertMessage from '../widgets/js/AlertMessage';

import { 
  ContainerForm,
  ContainerFormObject,
  ContainerFormObjectChild,
  ContainerFormObjectChildCircle,
  ContainerFormObjectChildCircleSvg,
  ContainerFormObjectChildText,
  ContainerFormObjectChildInput,
  ContainerFormObjectChildButton,
  ContainerFormObjectChildContainerPlaceholder,
  ContainerFormObjectChildPlaceholder

} from '../js/Styles';

import "../css/Login.css"

function Login(props) {

  const history = useHistory("");
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  //varibles para crear un alertMessage
  const [alert_message, setAlertMessage] = useState(false);
  const [option_alert_message, setOptionAlertMessage] = useState("");
  const [title_alert_message, setTitleAlertMessage] = useState("");
  const [description_alert_message, setDescriptionAlertMessage] = useState("");
  const apiDomain = process.env.REACT_APP_DOMAIN_API;
  const withSSL = process.env.REACT_APP_SSL;

  const Login = async(event) => {

    event.preventDefault();

    const credentials = {

      "email" :email,
      "password": password

    }

    const mensajes = [];

    function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i];
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
      }

      if (email === "") {
        mensajes.push("el email");
      }
      if (password === "") {
        mensajes.push("la contraseña");
      }
    
      if (mensajes.length > 0) {
        setAlertMessage(true);
        setOptionAlertMessage("warning");
        setTitleAlertMessage("Alto");
    
        let description = "";
        if (mensajes.length === 1) {
          description = "Falta "
          description += mensajes[0];
        } else if (mensajes.length === 2) {
          description = "Faltan "
          description += mensajes.join(" y ");
        } else {
          const lastMessage = mensajes.pop();
          description += `${mensajes.join(", ")} y ${lastMessage}`;
        }
    
        setDescriptionAlertMessage(description + ".");
      }

      if(email !== "" && password !== ""){
        const config = {     
          withCredentials: true
        }

        await axios.post(`http${withSSL}://${apiDomain}/api/auth/administrator/login/`, credentials, config)
        .then(

          (response) => {

            setAlertMessage(true);
            setOptionAlertMessage("success");
            setTitleAlertMessage("Exito");
            setDescriptionAlertMessage("Iniciando..");

            var csrftoken = getCookie('csrftoken');
            localStorage.setItem("token", csrftoken);
            let token = localStorage.token;      
            props.userLogin(token);
            history.push("/dashboard");
            console.log(response.data);
            }
          
        )
        .catch(

          (error) => {
            
          // Error
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
              if (error.response.status === 404){

                setAlertMessage(true);
                setOptionAlertMessage("error");
                setTitleAlertMessage("Error");
                setDescriptionAlertMessage("Credenciales de acceso incorrectas.");

              }

              else if (error.response.status === 403){

                setAlertMessage(true);
                setOptionAlertMessage("error");
                setTitleAlertMessage("Error");
                setDescriptionAlertMessage("Antes de inciar otra sesión tienes que cerrar la sesión que ya tienes iniciada.");

              }

            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the 
              // browser and an instance of
              // http.ClientRequest in node.js
              setAlertMessage(true);
              setOptionAlertMessage("error");
              setTitleAlertMessage("Error");
              setDescriptionAlertMessage("Servidor inactivo.");
          
            } else {
                // Something happened in setting up the request that triggered an Error              
                setAlertMessage(true);
                setOptionAlertMessage("error");
                setTitleAlertMessage("Error");
                setDescriptionAlertMessage("Error desconocido.");
          
            }
            
            }        
        )
      }

  }

  const closeAlertMessage = () => {
    console.log("Close alert message");
    setAlertMessage(false)
  }

  return (
    <ContainerForm>
      <br/>
      <br/>
      {
        alert_message ? 
        <AlertMessage option={option_alert_message} title={title_alert_message} description={description_alert_message} closeAlertMessage={closeAlertMessage}/>
        :
        <div></div>
      }
      <ContainerFormObject onSubmit={Login}> 

          <ContainerFormObjectChild>

            <ContainerFormObjectChildCircle>

              <ContainerFormObjectChildCircleSvg focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"></path></ContainerFormObjectChildCircleSvg>

            </ContainerFormObjectChildCircle>
            
            <ContainerFormObjectChildText>Login</ContainerFormObjectChildText>
            

          </ContainerFormObjectChild>

          <ContainerFormObjectChild>
            
            <ContainerFormObjectChildContainerPlaceholder>
              <ContainerFormObjectChildPlaceholder>Email*</ContainerFormObjectChildPlaceholder>
            </ContainerFormObjectChildContainerPlaceholder>
            <ContainerFormObjectChildInput  type="text" value={email} onChange={(event => setEmail(event.target.value))} autoComplete="off" pattern="[^@]+@[^@]+\.[a-zA-Z]{2,6}"/>

            <ContainerFormObjectChildContainerPlaceholder>
              <ContainerFormObjectChildPlaceholder>Contraseña*</ContainerFormObjectChildPlaceholder>
            </ContainerFormObjectChildContainerPlaceholder>
            <ContainerFormObjectChildInput type="password" value={password} onChange={(event => setPassword(event.target.value))} autoComplete="off" />

          </ContainerFormObjectChild>

          <ContainerFormObjectChild>

            <ContainerFormObjectChildButton type="submit">Login</ContainerFormObjectChildButton>

          </ContainerFormObjectChild>

          <ContainerFormObjectChild>

            <Link className="Container-Login-Form-Object-Child-Text" to="/reset_password">

              <span>¿Has olvidado tu contraseña?</span>            

            </Link>

            <Link className="Container-Login-Form-Object-Child-Text" to="/signup">

              <span>¿No tienes una cuenta? Crea una</span>

            </Link>

          </ContainerFormObjectChild>

      </ContainerFormObject>


    </ContainerForm>
  );
}

export default Login;