import React, { useState, useEffect, useRef } from "react";
import { useHistory } from 'react-router-dom';
import "../css/Dashboard.css"
import Logout from "./Logout";
import { SpanShowMoreInCalendar, ContainerCalendar } from '../js/Styles'; // Ajusta la ruta según tu estructura
import CustomToolBar from '../widgets/js/CustomToolBar';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es'; // Importa la localización en español
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Modal from 'react-modal'; // Importa react-modal

moment.locale('es'); // Establece la localización en español
const localizer = momentLocalizer(moment);

// Personaliza los textos de los botones en español
const messages = {
    allDay: 'Todo el día',
    previous: 'Anterior',
    next: 'Siguiente',
    today: 'Hoy',
    month: 'Mes',
    week: 'Semana',
    day:  'Día',
    agenda: 'Agenda',
    date: 'Fecha',
    time: 'Hora',
    event: 'Reservación', // Cambia el texto de "Event" a "Evento"
    showMore: total => (
        <SpanShowMoreInCalendar>{`+(${total}) más`}</SpanShowMoreInCalendar>
    ),
};

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo semi-transparente
        zIndex: 1000, // Asegura que el modal esté en la parte superior
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
        backgroundColor: 'black', //this works
        color: 'white',
        fontFamily: '"Zen Dots", sans-serif',
        fontSize: '0.58em',
        letterSpacing: '0.1px',
        textAlign: 'center',
        margin: '0px 0px 0px 0px',
        padding: '1px 0px 10px 0px',
        outline: 'none'
    };

    return {
        style,
    };
};

const calendarStyle = () => {
    return {
        style: {
        backgroundColor: 'transparent', //this works
        }
    }
}	

function Dashboard() {    
    
    const history = useHistory();
    const token = localStorage.token

    const socketRef = useRef(null);
    const [reservations, setReservations] = useState([]); // Estado para almacenar las reservas
    const [selectedEvent, setSelectedEvent] = useState(null); // Estado para el evento seleccionado
    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para mostrar/ocultar el modal
    const apiDomain = process.env.REACT_APP_DOMAIN_API;
    
    useEffect(()=>{
        
        if(token === undefined){
            history.push("/login");
        }
        else if(token === null){
            
            history.push("/login");
    
        }
        else{

            console.log("token activo: ", token)

        }

        // Reemplaza 'ws://tu_servidor_websocket' con la URL de tu servidor WebSocket
        socketRef.current = new WebSocket(`ws://${apiDomain}/ws/reservations/`);

        socketRef.current.onopen = () => {
            console.log('Conexión WebSocket abierta.');
        };

        socketRef.current.onmessage = (event) => {
            // Handle messages received from the WebSocket server
            console.log('Mensaje recibido del servidor:', event.data);

            // Parsea el mensaje como JSON si es necesario
            const data = JSON.parse(event.data);
            
            // Actualiza el estado de las reservas con los datos recibidos
            setReservations(data.reservations);
        };

        socketRef.current.onclose = () => {
            console.log('Conexión WebSocket cerrada.');
        };

        return () => {
            // Cierra la conexión WebSocket cuando el componente se desmonta
            socketRef.current.close();
        };

    }, []);

    // Convierte las reservas en un formato adecuado para react-big-calendar
    const events = reservations.map((reservation) => ({
        id: reservation.id,
        title: reservation.nombre,
        start: new Date(reservation.fecha_hora_reservacion),
        end: new Date(reservation.fecha_hora_reservacion), // Puedes ajustar esto según tus necesidades
    }));

    const sendMessage = () => {
        const message = 'Hola, servidor WebSocket!';
        console.log(message);
        socketRef.current.send(message);
    };

    // Manejador de eventos cuando se selecciona un evento
    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
        setIsModalOpen(true);
    };

    // Manejador para cerrar el modal
    const closeModal = () => {
        setIsModalOpen(false);
    };

    
    return (
        <div className="Dashboard-Container">
            <ContainerCalendar>
                <Calendar
                    localizer={localizer}
                    events={events} // Utiliza la función para agrupar y contar las reservas
                    toolbar={true}
                    views={{
                        month: true,
                        week: true,
                        day: true,
                        agenda: true,
                    }}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 650, margin:'100px 20px 20px 20px' }} // Ajusta la altura según tus necesidades
                    messages={messages} // Agrega los mensajes personalizados para los botones
                    selectable
                    onSelectEvent={handleSelectEvent} // Controlador para eventos seleccionados
                    eventPropGetter={eventStyleGetter} // Aplica el estilo personalizado a los eventos
                    dayPropGetter={calendarStyle}
                    components={{
                        toolbar: (props) => {
                        console.log("Views en Calendar:", props.views);
                        return (
                            <CustomToolBar
                            {...props}
                            onView={(view) => {
                                // Maneja la lógica cuando se cambia la vista (Month, Week, Day)
                                console.log("onview: "+view)
                                props.onView(view);
                            }}
                            onNavigate={(action) => {
                                // Maneja la lógica cuando se navega (Today, Prev, Next)
                                props.onNavigate(action);
                            }}
                            />
                        );
                        }, // Usa el componente de barra de herramientas personalizada
                    }}
                />
            </ContainerCalendar>
            {/* Modal para mostrar detalles del evento */}
            <Modal
                style={customStyles}
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                ariaHideApp={false} 
            >
                {selectedEvent && (
                    <div>
                        <h2>{selectedEvent.title}</h2>
                        <p>Fecha de inicio: {selectedEvent.start.toLocaleString()}</p>
                        <p>Fecha de finalización: {selectedEvent.end.toLocaleString()}</p>
                        {/* Agrega más detalles del evento aquí */}
                        <button onClick={closeModal}>Cerrar</button>
                    </div>
                )}
            </Modal>
            <Logout />
                
        </div>
    )
}

export default Dashboard
