import React, { useEffect, useState } from 'react';
import UniversalMediaElement from '../widgets/js/UniversalMediaElement'
import SplashScreen from '../widgets/js/SplashScreen';
import axios from 'axios';

function Menu() {
  const [showSplash, setShowSplash] = useState(true);
  const [videos_for_home_page, setVideosForHomePage] = useState([]); // Estado para almacenar las imagenes del carrousel
  const apiDomain = process.env.REACT_APP_DOMAIN_API;
  const withSSL = process.env.REACT_APP_SSL;

  const get_list_objects = async() => {

    await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/media_content_home_page/16/`).then(
      response => {
        const videos = response.data[0].videos.map(video => video.video);
        var order_videos = [];

        for(var i0 = 0; i0 < videos.length; i0++){
          if(videos[i0].includes(".mp4")){
            order_videos[0] = videos[i0];
          }

          if(videos[i0].includes(".webm")){
            order_videos[1] = videos[i0];
          }

          if(videos[i0].includes(".mov")){
            order_videos[2] = videos[i0];
          }
          
        }

        setVideosForHomePage(order_videos)
      }
    ).catch(
      error => console.log(error)
    )

  }

  useEffect(() => {
    get_list_objects();
    setTimeout(() => {
      setShowSplash(false);
    }, 1500); // Cambia este valor al tiempo deseado en milisegundos
  }, []);

  return (
    <>
    {
      showSplash ? (
        <SplashScreen />
      ) : (
        <>
        <UniversalMediaElement option_to_video_or_image={0} title_info_page_button={"CONOCE EL MENÚ"} option_to_navigate_button_media={"/food_menu"} videos_for_home_page={videos_for_home_page}/>
        </>
      )
    }
    </>
  )
}

export default Menu