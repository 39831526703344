import { 
    ContainerArticle,
    ContainerContactInfoImage,
    ContactInfoImage,
    ContactInfoContainerTextInImage,
    SpanViewHome1,
    SpanViewHome2,
    ContainerContactInfoText,
    ContainerContactInfoTextChild,
    ContactInfoTextChild1,
    ContactInfoTextChild2
} from '../js/Styles'; // Ajusta la ruta según tu estructura

import React, { useEffect, useState } from 'react';
import axios from 'axios';

function ContactInfo() {
    const [images_for_contact_info, setImagesForContactInfo] = useState([]); // Estado para almacenar las imagenes del carrousel
    const apiDomain = process.env.REACT_APP_DOMAIN_API;
    const withSSL = process.env.REACT_APP_SSL;

    const get_list_objects = async() => {

        await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/media_content/14/`).then(
            response => {
                const imagenes = response.data[0].imagenes.map(imagen => imagen.imagen);
                setImagesForContactInfo(imagenes)
            }
        ).catch(
            error => console.log(error)
        )

    }

    useEffect(() => {
        get_list_objects();
    }, []);
    
    return (
        <div>
            <ContainerArticle>
                <ContainerContactInfoImage>
                    <ContactInfoImage src={images_for_contact_info[0]} />
                    <ContactInfoContainerTextInImage>
                        <SpanViewHome1 to="/">R. Bardo</SpanViewHome1>
                        <SpanViewHome2>contacto</SpanViewHome2>
                    </ContactInfoContainerTextInImage>
                </ContainerContactInfoImage>
                <ContainerContactInfoText>
                    <ContainerContactInfoTextChild>
                        <ContactInfoTextChild1>horario</ContactInfoTextChild1>
                        <ContactInfoTextChild2><ContactInfoTextChild1>martes a sábado -</ContactInfoTextChild1> 1:30 pm a 1:00 am</ContactInfoTextChild2>
                        <ContactInfoTextChild2><ContactInfoTextChild1>domingo -</ContactInfoTextChild1> 1:30 pm a 07:00 pm</ContactInfoTextChild2>
                    </ContainerContactInfoTextChild>
                    <ContainerContactInfoTextChild>
                        <ContactInfoTextChild1>teléfono</ContactInfoTextChild1>
                        <ContactInfoTextChild2>2222495616</ContactInfoTextChild2>
                    </ContainerContactInfoTextChild>
                    <ContainerContactInfoTextChild>
                        <ContactInfoTextChild1>dirección</ContactInfoTextChild1>
                        <ContactInfoTextChild2>Teziutlán Sur 45-Locales 1 y 2, La Paz, 72160 Puebla, Pue.</ContactInfoTextChild2>
                    </ContainerContactInfoTextChild>
                </ContainerContactInfoText>
                <br/>
                <br/>
            </ContainerArticle>
        </div>
    );
}

export default ContactInfo