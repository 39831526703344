import UniversalMediaElement from '../widgets/js/UniversalMediaElement';
import {
    ContainerArticle, 
    ContainerFood, 
    ContainerFoodChild, 
    ContainerWineImage, 
    WineImage, 
    ContainerFoodText, 
    FoodTitle, 
    FoodPrice, 
    SpanForListItemsNull
} from '../js/Styles'; // Ajusta la ruta según tu estructura

import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Item9InFoodMenu() {

    const [wines, setWines] = useState([]); // Estado para almacenar los productos
    const [images_for_carousel, setImagesForCarousel] = useState([]); // Estado para almacenar las imagenes del carrousel
    const apiDomain = process.env.REACT_APP_DOMAIN_API;
    const withSSL = process.env.REACT_APP_SSL;

    const get_list_objects = async () => {
        try {
            const response = await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/wines/`);
            
            setWines(response.data);

            await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/media_content/9/`).then(
                response => {
                    const imagenes = response.data[0].imagenes.map(imagen => imagen.imagen);
                    setImagesForCarousel(imagenes)
                }
            ).catch(
                error => console.log(error)
            )
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        get_list_objects();
    }, []);

    return (
        <div>
            <UniversalMediaElement option_to_video_or_image={2} title_info_page_button={"DISFRUTA"} option_to_navigate_button_media={"container-article1"} images_for_carousel={images_for_carousel}/>
            <ContainerArticle>
            <ContainerFood>
            {
                wines.length > 0 ? (
                wines.map((object, index) => (
                    document.getElementById("container-article1").scrollIntoView({
                        behavior: "smooth", // Hace el desplazamiento suave
                        block: "start" // Alinea la parte superior del div en la parte superior de la ventana
                    }),
                    object.precio > 0 ?
                    <ContainerFoodChild key={object.nombre}>
                        <ContainerWineImage>
                            <WineImage src={object.imagen} />
                        </ContainerWineImage>
                        <ContainerFoodText>
                            <FoodTitle>{object.nombre}</FoodTitle>
                            <FoodPrice>CP: ${object.precio}</FoodPrice>
                        </ContainerFoodText>
                    </ContainerFoodChild>
                    : <div key={object.nombre}></div>
                ))
                ) : (
                <SpanForListItemsNull>Cargando vinos..</SpanForListItemsNull>
                )
            }
            </ContainerFood>
            </ContainerArticle>
        </div>
    );
}

export default Item9InFoodMenu;