import React, { useState, useEffect } from "react";
import { StyleImageCorousel } from "../../js/Styles";

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if(images.length > 0){
      const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 5500);//Total: 100%

      return () => clearInterval(intervalId);
    }
  }, [images]);

  return images.length > 0 ? <StyleImageCorousel src={images[currentIndex]} alt="carousel" /> : <></>;
};

export default ImageCarousel;
