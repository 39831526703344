import React, { useState } from "react";
import axios from "axios";
import "../css/Reset.css";

import AlertMessage from '../widgets/js/AlertMessage';

import { 
  ContainerForm,
  ContainerFormObject,
  ContainerFormObjectChild,
  ContainerFormObjectChildCircle,
  ContainerFormObjectChildCircleSvg,
  ContainerFormObjectChildText,
  ContainerFormObjectChildInput,
  ContainerFormObjectChildButton,
  ContainerFormObjectChildContainerPlaceholder,
  ContainerFormObjectChildPlaceholder

} from '../js/Styles'; // Ajusta la ruta

function Reset() {
  const [email, setEmail] = useState('');

  //varibles para crear un alertMessage
  const [alert_message, setAlertMessage] = useState(false);
  const [option_alert_message, setOptionAlertMessage] = useState("");
  const [title_alert_message, setTitleAlertMessage] = useState("");
  const [description_alert_message, setDescriptionAlertMessage] = useState("");
  const apiDomain = process.env.REACT_APP_DOMAIN_API;
  const withSSL = process.env.REACT_APP_SSL;

  const Send = async(event) => {

    event.preventDefault();

    const credentials = {

      "email" :email

    }

    if (email === "") {

      setAlertMessage(true);
      setOptionAlertMessage("warning");
      setTitleAlertMessage("Alto");
      setDescriptionAlertMessage("Faltan colocar el email.");

    }
    else{

      await axios({

        method: 'post',
        withCredentials: true,
        url: `http${withSSL}://${apiDomain}/api/auth/administrator/reset/`,
        data: credentials,

      })
      .then(

        () => {

          setAlertMessage(true);
          setOptionAlertMessage("success");
          setTitleAlertMessage("Exito");
          setDescriptionAlertMessage("El token fue enviado a tu correo electronico.");
      
        }
        
      )
      .catch(

        () => {
          
          setAlertMessage(true);
          setOptionAlertMessage("error");
          setTitleAlertMessage("Error");
          setDescriptionAlertMessage("Este correo no esta registrado.");
        }        

      )

    }

  }

  const closeAlertMessage = () => {
    console.log("Close alert message");
    setAlertMessage(false)
  }

  return (
    <ContainerForm>
      <br/>
      <br/>
      {
        alert_message ? 
        <AlertMessage option={option_alert_message} title={title_alert_message} description={description_alert_message} closeAlertMessage={closeAlertMessage}/>
        :
        <div></div>
      }
      <ContainerFormObject onSubmit={Send}> 

          <ContainerFormObjectChild>

            <ContainerFormObjectChildCircle>

              <ContainerFormObjectChildCircleSvg focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"></path></ContainerFormObjectChildCircleSvg>

            </ContainerFormObjectChildCircle>
            
            <ContainerFormObjectChildText>Reset password</ContainerFormObjectChildText>
            

          </ContainerFormObjectChild>

          <ContainerFormObjectChild>
            
          <ContainerFormObjectChildContainerPlaceholder>
            <ContainerFormObjectChildPlaceholder>Email*</ContainerFormObjectChildPlaceholder>
          </ContainerFormObjectChildContainerPlaceholder>
          <ContainerFormObjectChildInput type="text" value={email} onChange={(event => setEmail(event.target.value))} autoComplete="off" pattern="[^@]+@[^@]+\.[a-zA-Z]{2,6}"/>

          </ContainerFormObjectChild>

          <ContainerFormObjectChild>

            <ContainerFormObjectChildButton type="submit">Send</ContainerFormObjectChildButton>

          </ContainerFormObjectChild>
          <br/>
          <br/>
      </ContainerFormObject>
    </ContainerForm>
  );
}

export default Reset;
