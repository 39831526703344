import {
    ContainerArticle, 
    ContainerFood, 
    ContainerFoodChild, 
    ContainerFoodImage, 
    FoodImage, 
    ContainerFoodText, 
    FoodTitle, 
    FoodPrice, 
    FoodDescription, 
    SpanForListItemsNull
} from '../js/Styles'; // Ajusta la ruta según tu estructura

import React, { useEffect, useState } from 'react';
import axios from 'axios';

function MenuCocteleria() {

    const [products, setProducts] = useState([]); // Estado para almacenar los productos
    const [images_for_carousel, setImagesForCarousel] = useState([]); // Estado para almacenar los productos
    const apiDomain = process.env.REACT_APP_DOMAIN_API;
    const withSSL = process.env.REACT_APP_SSL;

    const get_List_objects = async() => {

        await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/products/7/`).then(
            response => {
                //console.log(response.data);
                setProducts(response.data)
            }
        ).catch(
            error => console.log(error)
        )

        await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/media_content/7/`).then(
            response => {
                const imagenes = response.data[0].imagenes.map(imagen => imagen.imagen);
                setImagesForCarousel(imagenes)
            }

        ).catch(
            error => console.log(error)
        )

    }

    

    useEffect(() => {
        get_List_objects();
    }, []);


    return (
    <div>
        <ContainerArticle>
            <ContainerFood>
            {
            products.length > 0 ? (
                products.map((object, index) => (
                <ContainerFoodChild key={index}>
                    <ContainerFoodImage>
                    <FoodImage src={object.imagen} />
                    </ContainerFoodImage>
                    <ContainerFoodText>
                    <FoodTitle>{object.nombre}</FoodTitle>
                    <FoodPrice>${object.precio}</FoodPrice>
                    <FoodDescription>{object.descripcion}</FoodDescription>
                    </ContainerFoodText>
                </ContainerFoodChild>
                ))
            ) : (
                <SpanForListItemsNull>Cargando bebidas..</SpanForListItemsNull>
            )
            }
        </ContainerFood>
        </ContainerArticle>
    </div>
    );
}

export default MenuCocteleria;