import React from 'react';
import '../css/SplashScreen.css';

function SplashScreen() {
  return (
    <div className='container-splash-screen'>
      <div className="splash-screen">
        <a className='circle'></a>
      </div>
    </div>

  );
}

export default SplashScreen;