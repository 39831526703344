import WineWithPrice from '../widgets/js/WineWithPrice';
import {
    ContainerArticleForElementsMenu,
    ContainerWines,
    ContainerSearchInputAndSelectedOptions,
    ContainerSearchInput,
    SearchInput,
    ContainerFilteredOptionsForSearch,
    FilteredOptionsForSearch,
    ContainerFilteredOptionsLevel2ForSearch,
    FilteredOptionLevel2ForSearch,
    SpanForListItemsNull,
    ContainerLogo,
    LogoImage,
    ContainerCircleForElementTheWine,
    FlagImageForSeletedOpcionInInputSearch

} from '../js/Styles'; // Ajusta la ruta según tu estructura

import logo from '../../assets/images/logo_letters_black.png';

import React, { useEffect, useState } from 'react';
import axios from 'axios';

function MenuVinosCopeo() {
    const [wines, setWines] = useState([]); // Estado para almacenar los productos

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredWines, setFilteredWines] = useState([]);
    
    const [filteredForSelectedWines, setFilteredForSelectedWines] = useState([]);
    
    const [filteredOption, setFilteredOption] = useState(0);
    const [filteredOptionString, setFilteredOptionString] = useState('nombre');
    const [images_for_carousel, setImagesForCarousel] = useState([]); // Estado para almacenar las imagenes del carrousel
    const apiDomain = process.env.REACT_APP_DOMAIN_API;
    const withSSL = process.env.REACT_APP_SSL;
    
    const get_list_objects = async () => {
        try {
            const response = await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/wines/`);
            
            var data = [];

            if(response.data.length > 0){

                for(var i0 = 0; i0 < response.data.length; i0++){
                    if(response.data[i0].precio > 0){
                        var object_for_data = {
                            "id": response.data[i0].id,
                            "nombre": response.data[i0].nombre,
                            "pais": response.data[i0].pais,
                            "imagen_bandera_pais": response.data[i0].imagen_bandera_pais,
                            "tipo": response.data[i0].tipo,
                            "variedad": response.data[i0].variedad,
                            "origen_variedad": response.data[i0].origen_variedad,
                            "fermentacion": response.data[i0].fermentacion,
                            "crianza": response.data[i0].crianza,
                            "barrica": response.data[i0].barrica,
                            "temperatura_servicio": response.data[i0].temperatura_servicio,
                            "graducacion_alcoholica": response.data[i0].graducacion_alcoholica,
                            "vista": response.data[i0].vista,
                            "nariz": response.data[i0].nariz,
                            "boca": response.data[i0].boca,
                            "maridaje": response.data[i0].maridaje,
                            "imagen": response.data[i0].imagen,
                            "precio": response.data[i0].precio,
                            "precio_bot": response.data[i0].precio,
                            "fecha_alta": response.data[i0].fecha_alta,
                            "fecha_modificacion": response.data[i0].fecha_modificacion
                        };

                        data.push(object_for_data);
                    }
                }
                setWines(data);
                setFilteredWines(data); // Inicialmente mostrar todos los vinos
            }

            await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/media_content/8/`).then(
                response => {
                    const imagenes = response.data[0].imagenes.map(imagen => imagen.imagen);
                    setImagesForCarousel(imagenes)
                }
            ).catch(
                error => console.log(error)
            )
        } catch (error) {
            console.log(error);
        }
    }

    // Función para manejar cambios en la entrada del usuario
    const handleSearchChange = (e, ) => {
        var term;

        if(typeof e === 'string'){
            term = e;
        }
        else{
            term = e.target.value;
        }

        setSearchTerm(term);

        // Filtrar los vinos basándose en el término de búsqueda o mostrar todos si no hay término
        const filtered = term
        ? wines.filter((wine) => {
            let output;
            if(filteredOption === 0){
                output = wine.nombre.toLowerCase().includes(term.toLowerCase());
            }
            if(filteredOption === 1){
                output = wine.pais.toLowerCase().includes(term.toLowerCase());
            }
            if(filteredOption === 2){
                output = wine.tipo.toLowerCase().includes(term.toLowerCase());
            }
            if(filteredOption === 3){
                output = wine.variedad.toLowerCase().includes(term.toLowerCase());
            }

            //console.log(wine);
            //console.log(filteredOption);
            
            return output;
        })
        : wines;

        //console.log("filtered: ");
        //console.log(filtered);

        setFilteredWines(filtered);

        const uniqueWines = [];
        const seenTypes = new Set();

        if(term !== ""){
            if(filteredOption === 0){
                //se eliminaran los resultados donde se repitan los nombres
                for (const wine of filtered) {
                    if (!seenTypes.has(wine.nombre)) {
                        uniqueWines.push(wine);
                        seenTypes.add(wine.nombre);
                    }
                }
                setFilteredForSelectedWines(uniqueWines);
            }
            else if(filteredOption === 1){
                //se eliminaran los resultados donde se repitan los paises
                for (const wine of filtered) {
                    if (!seenTypes.has(wine.pais)) {
                        uniqueWines.push(wine);
                        seenTypes.add(wine.pais);
                    }
                }
                setFilteredForSelectedWines(uniqueWines);
            }
            else if(filteredOption === 2){
                //se eliminaran los resultados donde se repitan los tipos de vinos
                for (const wine of filtered) {
                    if (!seenTypes.has(wine.tipo)) {
                        uniqueWines.push(wine);
                        seenTypes.add(wine.tipo);
                    }
                }
                setFilteredForSelectedWines(uniqueWines);
            }
            else if(filteredOption === 3){
                //se eliminaran los resultados donde se repitan los tipos de variedad
                for (const wine of filtered) {
                    if (!seenTypes.has(wine.variedad)) {
                        uniqueWines.push(wine);
                        seenTypes.add(wine.variedad);
                    }
                }
                setFilteredForSelectedWines(uniqueWines);
            }
        }
        else{
            setFilteredForSelectedWines([]);
        }
        
    };

    const handleFilteredOptionForSearch = (option) => {
        //console.log(`option for filtered search: ${option}`);
        setFilteredOption(option);

        if(option === 0){
            setFilteredOptionString('nombre')
        }
        if(option === 1){
            setFilteredOptionString('país')
        }
        if(option === 2){
            setFilteredOptionString('tipo')
        }
        if(option === 3){
            setFilteredOptionString('variedad')
        }

        setSearchTerm("");
        setFilteredForSelectedWines([]);
    };

    const setSelectedSearchTerm = (selectedOption) => {
        
        if(filteredOption === 0){
            setSearchTerm(selectedOption.nombre);
            handleSearchChange(selectedOption.nombre);
        }
        if(filteredOption === 1){
            setSearchTerm(selectedOption.pais);
            handleSearchChange(selectedOption.pais);
        }
        if(filteredOption === 2){
            setSearchTerm(selectedOption.tipo);
            handleSearchChange(selectedOption.tipo);
        }
        if(filteredOption === 3){
            setSearchTerm(selectedOption.variedad);
            handleSearchChange(selectedOption.variedad);
        }

        setFilteredForSelectedWines([]);
    };

    useEffect(() => {
        get_list_objects();
        handleFilteredOptionForSearch(0);
    }, []);

    return (
        <div>
            <ContainerArticleForElementsMenu>
                
                {
                    wines.length > 0 ?
                    <ContainerSearchInputAndSelectedOptions>
                        <ContainerSearchInput>
                            <SearchInput
                                type="text"
                                placeholder={`Buscar vino por ${filteredOptionString}...`}
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </ContainerSearchInput>
                        <ContainerFilteredOptionsForSearch> 
                            <FilteredOptionsForSearch onClick={()=> handleFilteredOptionForSearch(0)}>Nombre</FilteredOptionsForSearch>
                            <FilteredOptionsForSearch onClick={()=> handleFilteredOptionForSearch(1)}>País</FilteredOptionsForSearch>
                            <FilteredOptionsForSearch onClick={()=> handleFilteredOptionForSearch(2)}>Tipo</FilteredOptionsForSearch>
                            <FilteredOptionsForSearch onClick={()=> handleFilteredOptionForSearch(3)}>Variedad</FilteredOptionsForSearch>
                        </ContainerFilteredOptionsForSearch>
                        <ContainerFilteredOptionsLevel2ForSearch> 
                            {
                                filteredForSelectedWines.length > 0 ?
                                filteredForSelectedWines.map((object, index) => (
                                    <FilteredOptionLevel2ForSearch key={index} onClick={()=> setSelectedSearchTerm(object)}>
                                        {
                                            filteredOption === 0 ?
                                            object.nombre
                                            : filteredOption === 1 ?
                                            <>
                                            <FlagImageForSeletedOpcionInInputSearch src={object.imagen_bandera_pais} />
                                            {object.pais}
                                            </>
                                            : filteredOption === 2 ?
                                            object.tipo
                                            : filteredOption === 3 ?
                                            object.variedad
                                            : ""
                                        }
                                    </FilteredOptionLevel2ForSearch>
                                ))
                                : <></>
                            }
                        </ContainerFilteredOptionsLevel2ForSearch>
                    </ContainerSearchInputAndSelectedOptions>
                    : <></>
                }
                {
                    wines.length > 0 ?
                    <ContainerWines>
                        {filteredWines.length > 0 ? (
                            filteredWines.map((object, index) => (
                                <WineWithPrice wine={object} index={index} key={index} />
                            ))
                        ) : (
                            <SpanForListItemsNull>No hay vinos que coincidan con la búsqueda.</SpanForListItemsNull>
                        )}
                    </ContainerWines>
                    : <ContainerWines><SpanForListItemsNull>Esperando vinos...</SpanForListItemsNull></ContainerWines>
                }

                <ContainerLogo>
                    <LogoImage src={logo} />
                </ContainerLogo>
            </ContainerArticleForElementsMenu>
        </div>
    );
}

export default MenuVinosCopeo;