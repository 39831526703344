import UniversalMediaElement from '../widgets/js/UniversalMediaElement';
import { 
  ContainerArticle, 
  ContainerFood, 
  ContainerFoodChild, 
  ContainerFoodImage, 
  FoodImage, 
  ContainerFoodText, 
  FoodTitle, 
  FoodPrice, 
  FoodDescription, 
  SpanForListItemsNull
} from '../js/Styles'; // Ajusta la ruta según tu estructura

import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Item1InFoodMenu() {

  const [products, setProducts] = useState([]); // Estado para almacenar los productos
  const [images_for_carousel, setImagesForCarousel] = useState([]); // Estado para almacenar las imagenes del carrousel
  const apiDomain = process.env.REACT_APP_DOMAIN_API;
  const withSSL = process.env.REACT_APP_SSL;

  const get_list_objects = async() => {

    await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/products/1/`).then(
      response => {
        //console.log(response.data);
        setProducts(response.data)
      }

    ).catch(
        error => console.log(error)
    )

    await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/media_content/1/`).then(
      response => {
          const imagenes = response.data[0].imagenes.map(imagen => imagen.imagen);
          setImagesForCarousel(imagenes)
      }
    ).catch(
        error => console.log(error)
    )
  }

  useEffect(() => {
    get_list_objects();
  }, []);

  return (
    <div>
      <UniversalMediaElement option_to_video_or_image={2} title_info_page_button={"DISFRUTA"} option_to_navigate_button_media={"container-article1"} images_for_carousel={images_for_carousel}/>
      <ContainerArticle>
        <ContainerFood>
        {
          products.length > 0 ? (
            products.map((object, index) => (
              document.getElementById("container-article1").scrollIntoView({
                behavior: "smooth", // Hace el desplazamiento suave
                block: "start" // Alinea la parte superior del div en la parte superior de la ventana
              }),
              <ContainerFoodChild key={index}>
                {
                  object.imagen !== "" ?
                  <ContainerFoodImage>
                    <FoodImage src={object.imagen} />
                  </ContainerFoodImage>
                  : <></>
                }
                <ContainerFoodText>
                  <FoodTitle>{object.nombre}</FoodTitle>
                  <FoodPrice>${object.precio}</FoodPrice>
                  <FoodDescription>{object.descripcion}</FoodDescription>
                </ContainerFoodText>
              </ContainerFoodChild>
            ))
          ) : (
            <SpanForListItemsNull>Cargando alimentos..</SpanForListItemsNull>
          )
        }
      </ContainerFood>
    </ContainerArticle>
    </div>
  );
}

export default Item1InFoodMenu;
