import React, { useState, useEffect } from "react";
import {Link, useHistory} from "react-router-dom";

import '../css/UniversalMediaElement.css';
import thumbanil from '../../../assets/videos/thumbanil.jpg'

import ImageCarousel from "./ImageCarousel";

function UniversalMediaElement({option_to_video_or_image, id_info_image, route_info_image,  title_info_page, title_info_page_description, title_info_page_button, option_to_navigate_button_media, images_for_carousel, videos_for_home_page}) {

  const history = useHistory();

  useEffect(() => {

    const rectangulo = document.getElementById('rectangleIcon');
    const icono = document.getElementById('icon');
    const video = document.getElementById('video1');
    
    let terminacionVideo = 0;
    const animationDuration = 3500; // Duración total de la animación en milisegundos
    
    function checkProgress() {
      if (rectangulo) {
        const animations = rectangulo.getAnimations();
        if (animations.length > 0) {
          const currentTime = performance.now() - animations[0].startTime;
          const progreso = (currentTime / animationDuration) * 100;
    
          if (progreso >= 80) {
            icono.classList.add('mostrar');
          }
    
          if (currentTime < animationDuration) {
            requestAnimationFrame(checkProgress);
          }
        }
      }
    }
    
    if (video) {
      video.addEventListener('ended', function () {
        terminacionVideo++;
        console.log('El video ha llegado al final ' + terminacionVideo);
    
        if (terminacionVideo > 0) {
          icono.style.transform = 'rotate(360deg)';
          icono.style.transition = 'transform 1.8s ease-in-out';
    
          setTimeout(function () {
            icono.style.transform = 'none';
            icono.style.transition = 'none';
          }, 5000);
        }
    
        video.play();
      });
    }
    
    checkProgress();
  }, []);

  const navigateToButtonMedia = () => {
    if(option_to_navigate_button_media === "container-article1"){
      document.getElementById(option_to_navigate_button_media).scrollIntoView({
        behavior: "smooth", // Hace el desplazamiento suave
        block: "start" // Alinea la parte superior del div en la parte superior de la ventana
      });
    }
    else{
      history.push(option_to_navigate_button_media); // Navegar a la ruta /food_menu
    }
  };

  function newTab(url) {
    window.open(url, '_blank');
  }

  return (
    <div>
      <div className="containerSocialNetworks">
          <div tabIndex={5} onClick={() => newTab('https://wa.me/2225594450/')} className="socialNetworks">
              <div></div>
          </div>
          <div tabIndex={6} onClick={() => newTab('https://facebook.com/profile.php?id=100087916872647')} className="socialNetworks">
              <div></div>
          </div>
          <div tabIndex={7} onClick={() => newTab('https://www.instagram.com/restaurante.bardo/')} className="socialNetworks">
              <div></div>
          </div>
      </div>

      <article className="container-article">
          {
              option_to_video_or_image === 0 ? (
                  <video autoPlay muted playsInline poster={thumbanil}src={videos_for_home_page[0]} id="video1">
                      <source src={videos_for_home_page[0]} type="video/mp4"></source>
                      <source src={videos_for_home_page[1]} type="video/webm"></source>
                      <source src={videos_for_home_page[2]} type="video/mov"></source>
                  </video>
              ) : option_to_video_or_image === 1 ? (
                  <img id={id_info_image} src={route_info_image} alt={id_info_image}/>
              ) : option_to_video_or_image === 2 ? (
                <ImageCarousel images={images_for_carousel} />
              ) : (
                  <p>Opción no valia</p>
              )
          }

          <section id="containerMain">
              <div id="rectangleIcon"></div>
              <Link to="/">
                  <div id="icon"></div>
              </Link>

              
              <div id="containerInfoHome">
                  <div>
                  <Link className="spanViewHome1" to="/">R. Bardo</Link>
                  </div>
                  <div>
                      <p className="spanViewHome2">{title_info_page}</p>
                  </div>
                  <div>
                      <p className="spanViewHome3">{title_info_page_description}</p>
                  </div>
                  
                  {
                    title_info_page_button !== "DISFRUTA" ?
                    <div id="buttonViewHomeGoMenu" className="buttonViewHomeGoMenu" tabIndex={8} onClick={() => navigateToButtonMedia()}>
                      <span id="spanButtonViewHomeGoMenu" className="spanButtonViewHomeGoMenu">{title_info_page_button}</span>
                    </div> 
                    : <></>
                  }
                  
              </div>
          </section>
      </article>
      <article id="container-article1" className="container-article">

      </article>
    </div>
  )
}

export default UniversalMediaElement;
