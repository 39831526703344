import {
    ContainerArticle, 
    ContainerFood, 
    ContainerFoodChild, 
    ContainerDistilledText, 
    ContainerChildDistilledText, 
    DistilledTitle, 
    ContainerCircleForElementTheDistilled, 
    CircleForElementTheDistilled,   
    DistilledDescription,
    SpanForListItemsNull
} from '../js/Styles';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

function MenuDestilados() {
    const [products, setProducts] = useState([]); // Estado para almacenar los productos
    const [groupedProducts, setGroupedProducts] = useState({}); // Estado para almacenar productos agrupados por tipo
    const [images_for_carousel, setImagesForCarousel] = useState([]); // Estado para almacenar las imagenes del carrousel
    const apiDomain = process.env.REACT_APP_DOMAIN_API;
    const withSSL = process.env.REACT_APP_SSL;

    const get_list_objects = async () => {
    try {
        const response = await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/distilleds/`);
        setProducts(response.data);

        // Agrupar productos por tipo
        const grouped = {};
        response.data.forEach((product) => {
        const typeKey = product.tipo;
        if (!grouped[typeKey]) {
            grouped[typeKey] = [];
        }
        grouped[typeKey].push(product);
        });
        setGroupedProducts(grouped);

        await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/media_content/6/`).then(
        response => {
            const imagenes = response.data[0].imagenes.map(imagen => imagen.imagen);
            setImagesForCarousel(imagenes)
        }
        ).catch(
        error => console.log(error)
        )
    } catch (error) {
        console.log(error);
    }
    }

    useEffect(() => {
    get_list_objects();
    }, []);

    return (
    <div>
        <ContainerArticle>
        <ContainerFood>
            {
            products.length > 0 ? (
                Object.keys(groupedProducts).map((type, index) => (
                <div key={index}>
                    <ContainerFoodChild>
                    <ContainerDistilledText>
                    <DistilledTitle>{type}</DistilledTitle>
                        {groupedProducts[type].map((object, innerIndex) => (
                        <ContainerChildDistilledText key={innerIndex}>
                            <ContainerCircleForElementTheDistilled>
                            <CircleForElementTheDistilled/>
                            <DistilledDescription >
                                {object.nombre}
                                {
                                object.precioCp ? 
                                ` - CP: $${object.precioCp}` 
                                : <></>
                                }
                                {
                                object.precioBot ? 
                                ` - BOT: ${object.precioBot}` 
                                : <></>
                                }
                            </DistilledDescription>
                            </ContainerCircleForElementTheDistilled>
                        </ContainerChildDistilledText>
                    ))}
                    </ContainerDistilledText>
                    </ContainerFoodChild>
                </div>
                ))
            )
            : (
                <SpanForListItemsNull>Cargando destilados..</SpanForListItemsNull>
            )
            }
        </ContainerFood>
        </ContainerArticle>
    </div>
    );
}

export default MenuDestilados;