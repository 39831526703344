import React, { useState } from "react";
import { useHistory, Link } from 'react-router-dom'
import axios from 'axios';

import AlertMessage from '../widgets/js/AlertMessage';

import "../css/Signup.css"
import { 
  ContainerForm,
  ContainerFormObject,
  ContainerFormObjectChild,
  ContainerFormObjectChildCircle,
  ContainerFormObjectChildCircleSvg,
  ContainerFormObjectChildText,
  ContainerFormObjectChildInput,
  ContainerFormObjectChildButton,
  ContainerFormObjectChildContainerPlaceholder,
  ContainerFormObjectChildPlaceholder

} from '../js/Styles'; // Ajusta la ruta

function Signup() {
  //Nota: no se puede crear un ususario si ya exite un login activo en el navegador

  const history = useHistory("");

  const [nombre, setNombre] = useState('');
  const [apellido_paterno, setApellido_paterno] = useState('');
  const [apellido_materno, setApellido_materno] = useState('');
  const [edad, setEdad] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  //varibles para crear un alertMessage
  const [alert_message, setAlertMessage] = useState(false);
  const [option_alert_message, setOptionAlertMessage] = useState("");
  const [title_alert_message, setTitleAlertMessage] = useState("");
  const [description_alert_message, setDescriptionAlertMessage] = useState("");

  const SetSignup = async(event) => {

    event.preventDefault();

    const credentials = {

      "nombre": nombre,
      "apellido_paterno": apellido_paterno,
      "apellido_materno": apellido_materno,
      "edad": edad,
      "email": email,
      "password": password

    }

    const apiDomain = process.env.REACT_APP_DOMAIN_API;
    const withSSL = process.env.REACT_APP_SSL;

    const mensajes = [];

    if (nombre === "") {
      mensajes.push("el nombre");
    }
    if (apellido_paterno === "") {
      mensajes.push("el apellido paterno");
    }
    if (apellido_materno === "") {
      mensajes.push("el apellido materno");
    }
    if (edad === undefined) {
      mensajes.push("la edad");
    }
    if (email === "") {
      mensajes.push("el email");
    }
    if (password === "") {
      mensajes.push("la contraseña");
    }
  
    if (mensajes.length > 0) {
      setAlertMessage(true);
      setOptionAlertMessage("warning");
      setTitleAlertMessage("Alto");
  
      let description = "";
      if (mensajes.length === 1) {
        description = "Falta "
        description += mensajes[0];
      } else if (mensajes.length === 2) {
        description = "Faltan "
        description += mensajes.join(" y ");
      } else {
        const lastMessage = mensajes.pop();
        description += `${mensajes.join(", ")} y ${lastMessage}`;
      }
  
      setDescriptionAlertMessage(description + ".");
    }
    
    if (nombre !== "" && apellido_paterno !== ""&& apellido_materno !== "" && edad !== undefined && email !== "" && password !== "") {
      await axios({

        method: 'post',
        withCredentials: true,
        url: `http${withSSL}://${apiDomain}/api/auth/administrator/signup/`,
        data: credentials,
  
      })
      .then(
  
        () => {
  
          setAlertMessage(true);
          setOptionAlertMessage("success");
          setTitleAlertMessage("Exito");
          setDescriptionAlertMessage("Usuario creado.");
          history.push("/login");  
      
        }
        
      )
      .catch(
  
        () => {
  
          setAlertMessage(true);
          setOptionAlertMessage("error");
          setTitleAlertMessage("Error");
          setDescriptionAlertMessage("Este usuario ya fue creado.");

        }        
  
      )
    }
  }

  const closeAlertMessage = () => {
    console.log("Close alert message");
    setAlertMessage(false)
  }

  return (
    <ContainerForm>
      <br/>
      <br/>
      {
        alert_message ? 
        <AlertMessage option={option_alert_message} title={title_alert_message} description={description_alert_message} closeAlertMessage={closeAlertMessage}/>
        :
        <div></div>
      }
      <ContainerFormObject onSubmit={SetSignup}> 

        <ContainerFormObjectChild>

          <ContainerFormObjectChildCircle>

            <ContainerFormObjectChildCircleSvg focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"></path></ContainerFormObjectChildCircleSvg>

          </ContainerFormObjectChildCircle>
          
          <ContainerFormObjectChildText>Registro</ContainerFormObjectChildText>
          

        </ContainerFormObjectChild>

        <ContainerFormObjectChild>

          <ContainerFormObjectChildContainerPlaceholder>
            <ContainerFormObjectChildPlaceholder>Nombre*</ContainerFormObjectChildPlaceholder>
          </ContainerFormObjectChildContainerPlaceholder>
          <ContainerFormObjectChildInput type="text" value={nombre} onChange={(event => setNombre(event.target.value))} autoComplete="off" minLength={5} maxLength={40}/>
    
          <ContainerFormObjectChildContainerPlaceholder>
            <ContainerFormObjectChildPlaceholder>Apellido paterno*</ContainerFormObjectChildPlaceholder>
          </ContainerFormObjectChildContainerPlaceholder>
          <ContainerFormObjectChildInput type="text" value={apellido_paterno} onChange={(event => setApellido_paterno(event.target.value))} autoComplete="off"  minLength={5} maxLength={40}/>
    
          <ContainerFormObjectChildContainerPlaceholder>
            <ContainerFormObjectChildPlaceholder>Apellido materno*</ContainerFormObjectChildPlaceholder>
          </ContainerFormObjectChildContainerPlaceholder>
          <ContainerFormObjectChildInput type="text" value={apellido_materno} onChange={(event => setApellido_materno(event.target.value))} autoComplete="off" minLength={5} maxLength={40}/>
    
          <ContainerFormObjectChildContainerPlaceholder>
            <ContainerFormObjectChildPlaceholder>Edad*</ContainerFormObjectChildPlaceholder>
          </ContainerFormObjectChildContainerPlaceholder>
          <ContainerFormObjectChildInput type="text" value={edad} onChange={(event => setEdad(event.target.value))} autoComplete="off"/>
    
          <ContainerFormObjectChildContainerPlaceholder>
            <ContainerFormObjectChildPlaceholder>Email*</ContainerFormObjectChildPlaceholder>
          </ContainerFormObjectChildContainerPlaceholder>
          <ContainerFormObjectChildInput type="text" value={email} onChange={(event => setEmail(event.target.value))} autoComplete="off" pattern="[^@]+@[^@]+\.[a-zA-Z]{2,6}"/>
          
          <ContainerFormObjectChildContainerPlaceholder>
            <ContainerFormObjectChildPlaceholder>Contraseña*</ContainerFormObjectChildPlaceholder>
          </ContainerFormObjectChildContainerPlaceholder>
          <ContainerFormObjectChildInput type="password" value={password} onChange={(event => setPassword(event.target.value))} autoComplete="off"/>

        </ContainerFormObjectChild>

        <ContainerFormObjectChild className="Register-Container-Form-Child">

          <ContainerFormObjectChildButton type="submit">Registrar</ContainerFormObjectChildButton>

        </ContainerFormObjectChild>

        <ContainerFormObjectChild>

          <Link className="Container-Form-Object-Child-Text" to="/login">

            <span>¿Ya tienes una cuenta? inicia sesión</span>            

          </Link>

        </ContainerFormObjectChild>

      </ContainerFormObject>

    </ContainerForm>
  );
}

export default Signup