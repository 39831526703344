import UniversalMediaElement from '../widgets/js/UniversalMediaElement';
import Wine from '../widgets/js/Wine';
import {
    ContainerArticle,
    ContainerWines,
    ContainerSearchInput,
    SearchInput,
    ContainerFilteredOptionsForSearch,
    FilteredOptionsForSearch,
    SpanForListItemsNull
} from '../js/Styles'; // Ajusta la ruta según tu estructura

import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Item8InFoodMenu() {
    const [wines, setWines] = useState([]); // Estado para almacenar los productos

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredWines, setFilteredWines] = useState([]);
    const [filteredOption, setFilteredOption] = useState(0);
    const [filteredOptionString, setFilteredOptionString] = useState('nombre');
    const [images_for_carousel, setImagesForCarousel] = useState([]); // Estado para almacenar las imagenes del carrousel
    const apiDomain = process.env.REACT_APP_DOMAIN_API;
    const withSSL = process.env.REACT_APP_SSL;
    
    const get_list_objects = async () => {
        try {
            const response = await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/wines/`);
            
            setWines(response.data);
            setFilteredWines(response.data); // Inicialmente mostrar todos los vinos

            await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/media_content/8/`).then(
                response => {
                    const imagenes = response.data[0].imagenes.map(imagen => imagen.imagen);
                    setImagesForCarousel(imagenes)
                }
            ).catch(
                error => console.log(error)
            )
        } catch (error) {
            console.log(error);
        }
    }

    // Función para manejar cambios en la entrada del usuario
    const handleSearchChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);

        // Filtrar los vinos basándose en el término de búsqueda o mostrar todos si no hay término
        const filtered = term
        ? wines.filter((wine) => {
            let output;
            if(filteredOption === 0){
                output = wine.nombre.toLowerCase().includes(term.toLowerCase());
            }
            if(filteredOption === 1){
                output = wine.pais.toLowerCase().includes(term.toLowerCase());
            }
            if(filteredOption === 2){
                output = wine.tipo.toLowerCase().includes(term.toLowerCase());
            }
            if(filteredOption === 3){
                output = wine.variedad.toLowerCase().includes(term.toLowerCase());
            }

            //console.log(wine);
            //console.log(filteredOption);
            
            return output;
        })
        : wines;

        setFilteredWines(filtered);
    };

    const handleFilteredOptionForSearch = (option) => {
        //console.log(`option for filtered search: ${option}`);
        setFilteredOption(option);

        if(option === 0){
            setFilteredOptionString('nombre')
        }
        if(option === 1){
            setFilteredOptionString('país')
        }
        if(option === 2){
            setFilteredOptionString('tipo')
        }
        if(option === 3){
            setFilteredOptionString('variedad')
        }
    };

    useEffect(() => {
        get_list_objects();
        handleFilteredOptionForSearch(0);
    }, []);

    return (
        <div>
            <UniversalMediaElement option_to_video_or_image={2} title_info_page_button={"DISFRUTA"} option_to_navigate_button_media={"container-article1"} images_for_carousel={images_for_carousel}/>
            <ContainerArticle>
                
                <ContainerSearchInput>
                    <SearchInput
                        type="text"
                        placeholder={`Buscar vino por ${filteredOptionString}...`}
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </ContainerSearchInput>
                <ContainerFilteredOptionsForSearch> 
                    <FilteredOptionsForSearch onClick={()=> handleFilteredOptionForSearch(0)}>Nombre</FilteredOptionsForSearch>
                    <FilteredOptionsForSearch onClick={()=> handleFilteredOptionForSearch(1)}>País</FilteredOptionsForSearch>
                    <FilteredOptionsForSearch onClick={()=> handleFilteredOptionForSearch(2)}>Tipo</FilteredOptionsForSearch>
                    <FilteredOptionsForSearch onClick={()=> handleFilteredOptionForSearch(3)}>Variedad</FilteredOptionsForSearch>
                </ContainerFilteredOptionsForSearch>
                <ContainerWines>
                    {filteredWines.length > 0 ? (
                        filteredWines.map((object, index) => (
                            document.getElementById("container-article1").scrollIntoView({
                                behavior: "smooth", // Hace el desplazamiento suave
                                block: "start" // Alinea la parte superior del div en la parte superior de la ventana
                            }),
                            <Wine wine={object} index={index} key={index} />
                        ))
                    ) : (
                        <SpanForListItemsNull>No hay vinos que coincidan con la búsqueda.</SpanForListItemsNull>
                    )}
                </ContainerWines>
            </ContainerArticle>
        </div>
    );
}

export default Item8InFoodMenu;