import UniversalMediaElement from '../widgets/js/UniversalMediaElement';
import AlertMessage from '../widgets/js/AlertMessage';
import CustomToolBar from '../widgets/js/CustomToolBar';


import React, { useEffect, useRef, useState } from 'react';
import {
    ContainerArticleForReservations, 
    ContainerCalendar, 
    SpanShowMoreInCalendar, 
    ContainerFormObjectReservation,

    ContainerFormObject,
    ContainerFormObjectChild,
    ContainerFormObjectChildCircle,
    ContainerFormObjectChildCircleWithImage,
    ContainerFormObjectChildText,
    ContainerFormObjectChildInput,
    ContainerFormObjectChildInputDatePicker,
    ContainerFormObjectChildButton,
    ContainerFormObjectChildContainerPlaceholder,
    ContainerFormObjectChildPlaceholder,
    ContainerFatherTextAlertMessage,
    InfoForClickReservationDay,
    IconInfoForClickReservationDay,
    SpanInfoForClickReservationDay
} from '../js/Styles'; // Ajusta la ruta según tu estructura
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es'; // Importa la localización en español
import ReservacionLogo from '../../assets/images/reservacionlogo.png';
import InfoLogo from '../../assets/images/infoLogo.png';

import dayjs from 'dayjs';

moment.locale('es'); // Establece la localización en español
const localizer = momentLocalizer(moment);

const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
        background: 'black',
        color: 'white',
        fontFamily: '"Zen Dots", sans-serif',
        fontSize: '0.58em',
        letterSpacing: '0.1px',
        textAlign: 'center',
        margin: '0px 0px 0px 0px',
        padding: '1px 0px 10px 0px',
        outline: 'none'
    };

    return {
        style,
    };
};

const calendarStyle = () => {
    return {
        style: {
            backgroundColor: 'transparent',
        }
    }
}	

function Reservations() {
    const containerRef = useRef(null);
    const socketRef = useRef(null);
    
    const [reservationAvailabilityFlag, setReservationAvailabilityFlag] = useState(false);
    const [reservations, setReservations] = useState([]); // Estado para almacenar las reservas
    const [total_places_in_the_restaurant, setTotalPlacesInTheRestaurant] = useState(null); // Estado para almacenar los objetos provinientes de la API
    
    const [name, setName] = useState('');
    const [reservation_date, setReservationDate] = useState('');
    // Mantén un objeto para almacenar las reservaciones por día
    const groupedEvents = {};

    //varibles para crear un alertMessage
    const [alert_message, setAlertMessage] = useState(false);
    const [option_alert_message, setOptionAlertMessage] = useState("");
    const [title_alert_message, setTitleAlertMessage] = useState("");
    const [description_alert_message, setDescriptionAlertMessage] = useState("");
    
    const [images_for_carousel, setImagesForCarousel] = useState([]); // Estado para almacenar las imagenes del carrousel
    const apiDomain = process.env.REACT_APP_DOMAIN_API;
    const withSSL = process.env.REACT_APP_SSL;

    const get_list_objects = async() => {

        await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/total_places_in_the_restaurant/1/`).then(
            response => {
                setTotalPlacesInTheRestaurant(response.data.total)
            }

        ).catch(
            error => console.log(error)
        )

        await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/media_content/12/`).then(
            response => {
                const imagenes = response.data[0].imagenes.map(imagen => imagen.imagen);
                setImagesForCarousel(imagenes)
            }
        ).catch(
            error => console.log(error)
        )

    }

    const fadeInOnScroll = () => {
        var elementos = document.getElementsByClassName("fade-in");

        for (var i = 0; i < elementos.length; i++) {
            var elemento = elementos[i];
            var posicion = elemento.getBoundingClientRect().top;
            var alturaVentana = window.innerHeight/1.2;
            var offset = 10; // Valor de desplazamiento adicional para controlar cuándo se debe mostrar el elemento
    
            if (posicion < alturaVentana - offset) {
                elemento.classList.add("visible");
                elemento.style.animation = "fadeInFromBottom 0.9s ease-in-out";
            } else {
                elemento.classList.remove("visible");
                elemento.style.animation = "";
            }
            
        }
    }

    window.addEventListener("scroll", fadeInOnScroll);

    useEffect(() => {
        fadeInOnScroll();
        get_list_objects();

        // Reemplaza 'ws://tu_servidor_websocket' con la URL de tu servidor WebSocket
        socketRef.current = new WebSocket(`ws${withSSL}://${apiDomain}/ws/reservations/`);

        socketRef.current.onopen = () => {
            console.log('Conexión WebSocket abierta.');
        };

        socketRef.current.onmessage = (event) => {
            // Handle messages received from the WebSocket server
            //console.log('Mensaje recibido del servidor:', event.data);

            // Parsea el mensaje como JSON si es necesario
            const data = JSON.parse(event.data);
            
            // Actualiza el estado de las reservas con los datos recibidos
            setReservations(data.reservations);
        };

        socketRef.current.onclose = () => {
            console.log('Conexión WebSocket cerrada.');
        };

        return () => {
            // Cierra la conexión WebSocket cuando el componente se desmonta
            socketRef.current.close();
        };
    }, []);

    // Función para agrupar y contar las reservas por día
    const groupAndCountReservations = () => {


        reservations.forEach((reservation) => {
            const dateKey = moment(reservation.fecha_hora_reservacion).format('YYYY-MM-DD');
            if (!groupedEvents[dateKey]) {
                groupedEvents[dateKey] = [];
            }
            groupedEvents[dateKey].push(reservation);
        });

        const events = [];

        Object.keys(groupedEvents).forEach((dateKey) => {
            const reservationsOnDate = groupedEvents[dateKey];
            const totalReservationsForDay = reservationsOnDate.length;

            let msg;

            if (totalReservationsForDay !== total_places_in_the_restaurant) {
                msg = `${totalReservationsForDay} reservaciones de ${total_places_in_the_restaurant}`;
            } else {
                msg = "Ya no hay lugares";
            }

            // Parsea la fecha como una fecha local para evitar problemas de zona horaria
            const date = moment(dateKey).toDate();

            // Luego, puedes agregar una entrada al array de eventos para ese día
            events.push({
                title: msg,
                start: date,
                end: date,
            });
        });

        return events;
    };

    // Personaliza los textos de los botones en español
    const messages = {
        allDay: 'Todo el día',
        previous: 'Anterior',
        next: 'Siguiente',
        today: 'Hoy',
        month: 'Mes',
        week: 'Semana',
        day:  'Día',
        agenda: 'Agenda',
        date: 'Fecha',
        time: 'Hora',
        event: 'Reservación', // Cambia el texto de "Event" a "Evento"
        showMore: total => {
            
            let outputMsg = "";
            let placesAvailable = total_places_in_the_restaurant - total-1;
            
            if(placesAvailable < 1){
                outputMsg = "Ya no hay lugares";
            } 
            else{
                outputMsg = `Disponibles ${placesAvailable}`;
            }

            return <SpanShowMoreInCalendar>{outputMsg}</SpanShowMoreInCalendar>;
        },
    };

    const handleDateClick = (slotInfo) => {
        // 'slotInfo' contiene información sobre el rango de fechas seleccionado
        const fechaInicio = slotInfo.start.toISOString().substring(0, 10); // Formato YYYY-MM-DD
        const fechaActual = moment().format('YYYY-MM-DD'); // Fecha y hora actual

        if(fechaInicio >= fechaActual){

            const fechaHoraActual = moment().format('HH:mm:ss'); // Formato YYYY-MM-DD HH:mm:ss
            const reservationsOnDate = groupedEvents[fechaInicio] || [];
            let msg;
            let fechaInicioConHora;
            const numeroDeReservaciones = reservationsOnDate.length;

            if (numeroDeReservaciones !== total_places_in_the_restaurant) {
                msg = `${numeroDeReservaciones} reservaciones de ${total_places_in_the_restaurant}`;
                
                // Combina la fecha de inicio con la hora actual
                fechaInicioConHora = `${fechaInicio} ${fechaHoraActual}`;
                setReservationDate(fechaInicioConHora);
                setReservationAvailabilityFlag(true);
                // Utiliza setTimeout para esperar un breve momento antes de desplazarte
                setTimeout(() => {
                    if (containerRef.current) {
                        console.log("Scrolling to container");
                        containerRef.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                        });
                    }
                }, 1); // Ajusta el tiempo de espera según sea necesario
                
            } else {
                msg = "Ya no hay lugares";
                setReservationAvailabilityFlag(false);
            }
    
            console.log(msg);
            console.log("Fecha del día clickeado:", fechaInicioConHora);    
        }
        else{
            setReservationAvailabilityFlag(false);
        }
    };

    const onChangeDatePicker = (date, dateString) => {
        console.log(dateString);
        setReservationDate(dateString);
    }

    const Reservation = async(event) => {

        event.preventDefault();

        const credentials = {
            "nombre" :name,
            "fecha_hora_reservacion": reservation_date
        }

        const clean_date_for_reservation = moment(reservation_date).format('YYYY-MM-DD HH:mm:ss');

        console.log("nombre: "+ name);
        console.log("fecha_hora_reservacion: "+ clean_date_for_reservation);

        if (name === "" || reservation_date === "") {
        
            setAlertMessage(true);
            setOptionAlertMessage("warning");
            setTitleAlertMessage("Alto");
            setDescriptionAlertMessage("Faltan algunos datos.");
        }else{
            await axios({
                method: 'post',
                withCredentials: true,
                url: `http${withSSL}://${apiDomain}/api/no_auth/reservations/`,
                data: credentials,
            })
            .then(
                () => {
                    setAlertMessage(true);
                    setOptionAlertMessage("success");
                    setTitleAlertMessage("Exito");
                    setDescriptionAlertMessage("Reservación creada");
                    setReservationAvailabilityFlag(false)
                    setName("")
                    setReservationDate("")
                }
            )
            .catch(
                () => {
                    document.body.classList.remove('loading-indicator');
                    
                    setAlertMessage(true);
                    setOptionAlertMessage("error");
                    setTitleAlertMessage("Error");
                    setDescriptionAlertMessage("ocurrio un error innesperado");
                }        
            )
        }
    }

    const closeAlertMessage = () => {
        console.log("Close alert message");
        setAlertMessage(false)
    }

    return (
        <div>
            <UniversalMediaElement option_to_video_or_image={2} title_info_page_button={"RESERVA"} option_to_navigate_button_media={"container-article1"} images_for_carousel={images_for_carousel}/>
            <ContainerArticleForReservations>
                <InfoForClickReservationDay>
                    <IconInfoForClickReservationDay src={InfoLogo} />
                    <SpanInfoForClickReservationDay>Mantener presionado 1s el día que se busca hacer la reservación</SpanInfoForClickReservationDay>
                </InfoForClickReservationDay>
                <ContainerCalendar>
                    <Calendar
                        selectable={true} // Asegúrate de que la selección esté habilitada
                        onSelectSlot={handleDateClick} // Agrega esta prop para manejar el clic en días
                        onSelectEvent={handleDateClick} 
                        localizer={localizer}
                        events={groupAndCountReservations()} // Utiliza la función para agrupar y contar las reservas
                        toolbar={true}
                        views={{
                            month: true,
                            week: false,
                            day: false,
                            agenda: false,
                        }}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 600 }} // Ajusta la altura según tus necesidades
                        messages={messages} // Agrega los mensajes personalizados para los botones
                        eventPropGetter={eventStyleGetter} // Aplica el estilo personalizado a los eventos
                        dayPropGetter={calendarStyle}
                        showAllEvents={true}
                        doShowMoreDrillDown={true}
                        components={{
                            toolbar: (props) => {
                                
                            return (
                                <CustomToolBar
                                {...props}
                                onView={(view) => {
                                    // Maneja la lógica cuando se cambia la vista (Month, Week, Day)
                                    console.log("onview: "+view)
                                    props.onView(view);
                                }}
                                onNavigate={(action) => {
                                    // Maneja la lógica cuando se navega (Today, Prev, Next)
                                    props.onNavigate(action);
                                }}
                                />
                            );
                            }, // Usa el componente de barra de herramientas personalizada
                        }}
                    />
                </ContainerCalendar>
            </ContainerArticleForReservations>
            {
                alert_message ? 
                <ContainerFatherTextAlertMessage>
                    <AlertMessage option={option_alert_message} title={title_alert_message} description={description_alert_message} closeAlertMessage={closeAlertMessage}/>
                </ContainerFatherTextAlertMessage>
                :
                <div></div>
            }
            {

                reservationAvailabilityFlag ?
                <ContainerFormObjectReservation className="fade-in" ref={containerRef}>
                    
                    <ContainerFormObject onSubmit={Reservation}>
                        <ContainerFormObjectChild>
                            <ContainerFormObjectChildCircle>
                                <ContainerFormObjectChildCircleWithImage src={ReservacionLogo}/>
                            </ContainerFormObjectChildCircle>
                            <ContainerFormObjectChildText>Crea una reservación</ContainerFormObjectChildText>
                        </ContainerFormObjectChild>

                        <ContainerFormObjectChild>
                            <ContainerFormObjectChildContainerPlaceholder>
                                <ContainerFormObjectChildPlaceholder>Nombre*</ContainerFormObjectChildPlaceholder>
                            </ContainerFormObjectChildContainerPlaceholder>
                            <ContainerFormObjectChildInput type="text" value={name} onChange={(event => setName(event.target.value))} autoComplete="off" minLength={5} maxLength={40}/>

                            <ContainerFormObjectChildContainerPlaceholder>
                                <ContainerFormObjectChildPlaceholder>Fecha de reservación*</ContainerFormObjectChildPlaceholder>
                            </ContainerFormObjectChildContainerPlaceholder>
                            <ContainerFormObjectChildInputDatePicker placeholder="Selecciona una fecha" showTime format="YYYY-MM-DD HH:mm:ss" value={reservation_date ? dayjs(reservation_date, 'YYYY-MM-DD HH:mm:ss') : null} onChange={onChangeDatePicker} />
                        </ContainerFormObjectChild>

                        <ContainerFormObjectChild className="Register-Container-Form-Child">
                            <ContainerFormObjectChildButton type="submit" /*disabled={option_alert_message === "success" ? true : false}*/>Reservar</ContainerFormObjectChildButton>
                        </ContainerFormObjectChild>
                        <br/>
                        <br/>
                    </ContainerFormObject>
                </ContainerFormObjectReservation>
                : <div></div>
            }
        </div>
    );
}

export default Reservations;