import CIcon  from '@coreui/icons-react';
import { cilCheckAlt, cilWarning, cilBurn, cilX } from '@coreui/icons';

import {ContainerAlertMesage, AlertMesage, ContainerIconAlertMessage, ContainerFatherTextAlertMessage, ContainerTextAlertMessage, TextAlertMessage} from '../../js/Styles'

function AlertMessage({icon, option, title, description, closeAlertMessage}) {
    const listenCloseAlertMessage=() => {
        closeAlertMessage()
        console.log('closeAlertMessage se llamó');
    }
    return (
        <ContainerAlertMesage option_alert_message={option}>
            <AlertMesage>
                <ContainerIconAlertMessage option_alert_message={option}>
                    {
                        option === "success" ? 
                            <CIcon icon={cilCheckAlt} className="flex-shrink-0 me-2" width={24} height={24} />
                        : option === "warning" ? 
                            <CIcon icon={cilWarning} className="flex-shrink-0 me-2" width={20} height={20} />
                        : 
                            <CIcon icon={cilBurn} className="flex-shrink-0 me-2" width={24} height={24} />
                    }
                </ContainerIconAlertMessage>
                <ContainerFatherTextAlertMessage>
                    <ContainerTextAlertMessage>
                        <TextAlertMessage>{title}</TextAlertMessage>
                        <TextAlertMessage>{description}</TextAlertMessage>
                    </ContainerTextAlertMessage>
                </ContainerFatherTextAlertMessage>
                <ContainerIconAlertMessage option_alert_message={option} tabIndex={1} onClick={()=> listenCloseAlertMessage()}>
                    <CIcon icon={cilX} className="flex-shrink-0 me-2" width={24} height={24} />
                </ContainerIconAlertMessage>
            </AlertMesage>
        </ContainerAlertMesage>
    )
}

export default AlertMessage