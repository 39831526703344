import React, { useEffect, useState } from 'react';
import UniversalMediaElement from '../widgets/js/UniversalMediaElement'
import SplashScreen from '../widgets/js/SplashScreen';
import axios from 'axios';

function ThePlace() {
  const [showSplash, setShowSplash] = useState(true);
  const [images_for_carousel, setImagesForCarousel] = useState([]); // Estado para almacenar las imagenes del carrousel
  const apiDomain = process.env.REACT_APP_DOMAIN_API;
  const withSSL = process.env.REACT_APP_SSL;

  const get_list_objects = async() => {

    await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/media_content/15/`).then(
      response => {
        const imagenes = response.data[0].imagenes.map(imagen => imagen.imagen);
        setImagesForCarousel(imagenes)
      }
    ).catch(
        error => console.log(error)
    )

  }

  useEffect(() => {
    get_list_objects();
    setTimeout(() => {
      setShowSplash(false);
    }, 1500); // Cambia este valor al tiempo deseado en milisegundos
  }, []);

  return (
    <>
    {
      showSplash ? (
        <SplashScreen />
      ) : (
        <>
          <UniversalMediaElement option_to_video_or_image={2} title_info_page_button={"CONOCE EL LUGAR"} option_to_navigate_button_media={"/food_menu"} images_for_carousel={images_for_carousel}/>
        </>
      )
    }
    </>
  )
}

export default ThePlace