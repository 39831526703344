import UniversalMediaElement from '../widgets/js/UniversalMediaElement';
import {
    ContainerArticleForElementsMenu, 
    ContainerElementsMenu, 
    ContainerElementMenuChild, 
    ContainerElementMenuImage, 
    ElementMenuTitle,
    ElementMenuImage, 
    ContainerElementMenuText,
    ContainerElementMenuDescription,
    ElementMenuDescription,  
    ContainerElementMenuPrice,
    ElementMenuPrice,  
    SpanForListItemsNull,
    ContainerLogo,
    LogoImage
} from '../js/Styles'; // Ajusta la ruta según tu estructura

import logo from '../../assets/images/logo_letters_black.png';


import React, { useEffect, useState } from 'react';
import axios from 'axios';

function MenuPostres() {

    const [products, setProducts] = useState([]); // Estado para almacenar los productos
    const [images_for_carousel, setImagesForCarousel] = useState([]); // Estado para almacenar las imagenes del carrousel
    const apiDomain = process.env.REACT_APP_DOMAIN_API;
    const withSSL = process.env.REACT_APP_SSL;

    const get_list_objects = async() => {

        await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/products/5/`).then(
        response => {
            if(response.data.length){
                var data = [];

                for(var i0 = 0; i0 < response.data.length; i0++){
    
                    var objeto_for_data = {
                        "id": response.data[i0].id,
                        "nombre": response.data[i0].nombre === null || response.data[i0].nombre === undefined ? "" : response.data[i0].nombre,
                        "descripcion": response.data[i0].descripcion === null || response.data[i0].descripcion === undefined ? "" : response.data[i0].descripcion,
                        "precio": response.data[i0].precio,
                        "categoria": response.data[i0].categoria === null || response.data[i0].categoria === undefined ? "" : response.data[i0].categoria,
                        "imagen": response.data[i0].imagen === null || response.data[i0].imagen === undefined ? "" : response.data[i0].imagen
                    }
    
                    //console.log("objeto_for_data:");
                    //console.log(objeto_for_data);
    
                    data.push(objeto_for_data);
                }

                // Ordenar data para que los objetos con imagen estén primero
                data.sort(function(a, b) {
                    if (a.imagen && !b.imagen) {
                        return -1; // a va antes que b
                    } else if (!a.imagen && b.imagen) {
                        return 1; // b va antes que a
                    } else {
                        return 0; // no cambiar el orden
                    }
                });
    
                setProducts(data)
            }
        }
        ).catch(
            error => console.log(error)
        )

        await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/media_content/5/`).then(
        response => {
            const imagenes = response.data[0].imagenes.map(imagen => imagen.imagen);
            setImagesForCarousel(imagenes)
        }
        ).catch(
            error => console.log(error)
        )

    }

    useEffect(() => {
        get_list_objects();
    }, []);

    return (
        <div>
        <ContainerArticleForElementsMenu>
            <ContainerElementsMenu>
            {
                products.length > 0 ? (
                products.map((object, index) => (
                    <ContainerElementMenuChild key={index}>
                        <ContainerElementMenuImage height={object.nombre === "TEJA DE NUECES" ? "0" : "0"}>
                            <ElementMenuTitle>{object.nombre}</ElementMenuTitle>
                            {
                                object.imagen !== "" ?
                                <ElementMenuImage src={object.imagen} />
                                : <></>
                            }
                        </ContainerElementMenuImage>
                        <ContainerElementMenuText>
                            {
                                object.descripcion !== "" ?
                                <ContainerElementMenuDescription>
                                    <ElementMenuDescription>{object.descripcion}</ElementMenuDescription>
                                </ContainerElementMenuDescription>
                                : <></>
                            }
                            <ContainerElementMenuPrice>
                                <ElementMenuPrice>${object.precio}</ElementMenuPrice>
                            </ContainerElementMenuPrice>
                        </ContainerElementMenuText>
                    </ContainerElementMenuChild>
                ))
                ) : (
                <SpanForListItemsNull>Cargando postres..</SpanForListItemsNull>
                )
            }
            </ContainerElementsMenu>
            <ContainerLogo>
                <LogoImage src={logo} />
            </ContainerLogo>
        </ContainerArticleForElementsMenu>
        </div>
    );
}

export default MenuPostres;