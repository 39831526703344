import React, { useEffect, useState } from 'react';
import {useHistory} from "react-router-dom";
import "../css/FoodMenu.css"
import axios from 'axios';

function FoodMenu() {
    var count = 0;
    const history = useHistory();
    const [images_for_elements_with_class_e_w_f_in, setImagesForElementsWithClassEWFIn] = useState([]); // Estado para almacenar las imagenes del la clase element-with-face-in
    const apiDomain = process.env.REACT_APP_DOMAIN_API;
    const withSSL = process.env.REACT_APP_SSL;

    const elements_for_food_menu = [
        'Para Empezar',
        'Entradas y ensaladas',
        'Pastas, risotos y sopas',
        'Carne, pescado y pollo',
        'Postres',
        'Destilados',
        'Bebidas',
        'Vinos',
        'V. Copeo'
    ];

    const get_list_objects = async() => {
    
        await axios.get(`http${withSSL}://${apiDomain}/api/no_auth/media_content/11/`).then(
            response => {
                const imagenes = response.data[0].imagenes.map(imagen => imagen.imagen);
                const nombres_imagenes = response.data[0].imagenes.map(imagen => imagen.nombre);

                setImagesForElementsWithClassEWFIn(imagenes);

                for(var i0 = 0; i0 < imagenes.length; i0++){
                    var item_with_class_element_with_face_in = document.getElementById(`element-with-face-in-${i0+1}`);

                    var posicion = searchPositionForItemFoodMenu(elements_for_food_menu[i0], nombres_imagenes)

                    if(imagenes[posicion] !== null){
                        item_with_class_element_with_face_in.style.backgroundImage = `url(${imagenes[posicion]})`;
                        item_with_class_element_with_face_in.style.backgroundPosition = 'center';
                        item_with_class_element_with_face_in.style.backgroundRepeat = 'no-repeat';
                        item_with_class_element_with_face_in.style.backgroundSize = 'cover';
                    }
                }

                
            }
        ).catch(
            error => console.log(error)
        )
    
    }

    function searchPositionForItemFoodMenu(search, images){
        var posicion = 0;
        
        for(var i0 = 0; i0 <= images.length; i0++){
            if(search == images[i0]){
                posicion = i0;
            }
        }

        return posicion
    }


    function fadeInOnScroll() {

        if(count === 0){
            var elementos = document.getElementsByClassName("fade-in");

            for (var i = 0; i < elementos.length; i++) {
                var elemento = elementos[i];
                var posicion = elemento.getBoundingClientRect().top;
                var alturaVentana = window.innerHeight/1.2;
                var offset = 100; // Valor de desplazamiento adicional para controlar cuándo se debe mostrar el elemento

                if (posicion < alturaVentana - offset || i < 2) {
                    elemento.classList.add("visible");
                    elemento.style.animation = "fadeInFromBottom 0.9s ease-in-out";
                } else {
                    elemento.classList.remove("visible");
                    elemento.style.animation = "";
                }
                
            }
        }
        else{
            console.log("Ya se cargaron las animaciones de la página");
        }

        // Verificar si estás en el final de la página
        var scrollPosition = window.scrollY;
        var windowHeight = window.innerHeight;
        var documentHeight = document.documentElement.scrollHeight;

        if (scrollPosition + windowHeight >= documentHeight) {
            //count ++;
            //console.log("Estás en el final de la página");
            //console.log(count);
            // Aquí puedes realizar las acciones que desees al llegar al final de la página
        }
    }

    function hoverEffect(id, option){

        //console.log("option: "+option);
        //console.log("hover: "+id)

        let elementWithHoverEffect1;
        let elementWithHoverEffect2;

        if(option === 1){
            elementWithHoverEffect1 = document.getElementById(`container-link-${id}`);
            elementWithHoverEffect2 = document.getElementById(`child-line-${id}`);

            elementWithHoverEffect1.style.opacity = '1';
            elementWithHoverEffect1.style.transition = 'opacity 0.9s ease-in-out';
        }

        if(option === 2){
            elementWithHoverEffect2 = document.getElementById(`child-line-${id}`);
            elementWithHoverEffect2.style.width = '0%';
            elementWithHoverEffect2.style.transition = 'width 0.4s ease-in-out';
        }

    }

    function noHoverEffect(id, option){
        //console.log("no option: "+option);
        //console.log("no hover: "+id)

        let elementWithHoverEffect1;
        let elementWithHoverEffect2;

        if(option === 1){
            elementWithHoverEffect1 = document.getElementById(`container-link-${id}`);

            elementWithHoverEffect1.style.opacity = '0';
            elementWithHoverEffect1.style.transition = 'opacity 0.9s ease-in-out';
        }

        if(option === 2){
            elementWithHoverEffect2 = document.getElementById(`child-line-${id}`);
            elementWithHoverEffect2.style.width = '50%';
            elementWithHoverEffect2.style.transition = 'width 0.4s ease-in-out';
        }
    }

    useEffect(() => {
        // Agregar event listeners aquí una vez que los elementos estén disponibles en el DOM
        const addEventListeners = () => {
            // Agregar event listeners a los elementos con IDs
            const elementsWithEventListeners = [
                'element-with-face-in-1',
                'element-with-face-in-2',
                'element-with-face-in-3',
                'element-with-face-in-4',
                'element-with-face-in-5',
                'element-with-face-in-6',
                'element-with-face-in-7',
                'element-with-face-in-8',
                'element-with-face-in-9'
            ];
    
            elementsWithEventListeners.forEach(id => {
                const element = document.getElementById(id);
                if (element) {
                    element.addEventListener('click', function(event) {

                        if(id === 'element-with-face-in-1'){
                            history.push('/item_1_in_food_menu'); // Navegar a la ruta /food_menu
                        }
                        if(id === 'element-with-face-in-2'){
                            history.push('/item_2_in_food_menu'); // Navegar a la ruta /food_menu
                        }
                        if(id === 'element-with-face-in-3'){
                            history.push('/item_3_in_food_menu'); // Navegar a la ruta /food_menu
                        }
                        if(id === 'element-with-face-in-4'){
                            history.push('/item_4_in_food_menu'); // Navegar a la ruta /food_menu
                        }
                        if(id === 'element-with-face-in-5'){
                            history.push('/item_5_in_food_menu'); // Navegar a la ruta /food_menu
                        }
                        if(id === 'element-with-face-in-6'){
                            history.push('/item_6_in_food_menu'); // Navegar a la ruta /food_menu
                        }
                        if(id === 'element-with-face-in-7'){
                            history.push('/item_7_in_food_menu'); // Navegar a la ruta /food_menu
                        }
                        if(id === 'element-with-face-in-8'){
                            history.push('/item_8_in_food_menu'); // Navegar a la ruta /food_menu
                        }
                        if(id === 'element-with-face-in-9'){
                            history.push('/item_9_in_food_menu'); // Navegar a la ruta /food_menu
                        }
                        window.scrollTo({ top: 0});
                    });
                    }
                });
            };
    
            addEventListeners();
            fadeInOnScroll();
    }, []); // El arreglo vacío [] asegura que el efecto se ejecute solo una vez

    if(images_for_elements_with_class_e_w_f_in.length == 0){
        get_list_objects();
    }
    return (
        <div className='container-food-menu'>
            <article className="fade-in">
                <section id="element-with-face-in-1" className="element-with-face-in" tabIndex={1} onMouseOver={() => hoverEffect(1, 1)} onMouseOut={()=> noHoverEffect(1, 1)}>
                    <div className="container-for-elements-with-face-in">
                        <div id="container-title-1" className="container-title">
                            <span className="child-title">Para Empezar</span>
                        </div>
                        <div id="container-link-1" className="container-link" onMouseOver={() => hoverEffect(1, 2)} onMouseOut={()=> noHoverEffect(1, 2)}>
                            <span className="child-link">Pruébalos</span>
                            <div id="child-line-1" className="child-line"></div>
                        </div>
                    </div>
                </section>
                <section id="element-with-face-in-2" className="element-with-face-in" tabIndex={2} onMouseOver={() => hoverEffect(2, 1)} onMouseOut={()=> noHoverEffect(2, 1)}>
                    <div className="container-for-elements-with-face-in">
                        <div id="container-title-2" className="container-title">
                            <span className="child-title">Entradas y ensaladas</span>
                        </div>
                        <div id="container-link-2" className="container-link" onMouseOver={() => hoverEffect(2, 2)} onMouseOut={()=> noHoverEffect(2, 2)}>
                            <span className="child-link">Conócelos</span>
                            <div id="child-line-2" className="child-line"></div>
                        </div>
                    </div>
                </section>
            </article>
            <article className="fade-in">
                <section id="element-with-face-in-3" className="element-with-face-in" tabIndex={3} onMouseOver={() => hoverEffect(3, 1)} onMouseOut={()=> noHoverEffect(3, 1)}>
                    <div className="container-for-elements-with-face-in">
                        <div id="container-title-3" className="container-title">
                            <span className="child-title">Pastas, risotos y sopas</span>
                        </div>
                        <div id="container-link-3" className="container-link"  onMouseOver={() => hoverEffect(3, 2)} onMouseOut={()=> noHoverEffect(3, 2)}>
                            <span className="child-link">Disfrútalos</span>
                            <div id="child-line-3" className="child-line"></div>
                        </div>
                    </div>
                </section>
            </article>
            <article className="fade-in">
                <section id="element-with-face-in-4" className="element-with-face-in" tabIndex={4} onMouseOver={() => hoverEffect(4, 1)} onMouseOut={()=> noHoverEffect(4, 1)}>
                    <div className="container-for-elements-with-face-in">
                        <div id="container-title-4" className="container-title">
                            <span className="child-title">Carne, pescado y pollo</span>
                        </div>
                        <div id="container-link-4" className="container-link" onMouseOver={() => hoverEffect(4, 2)} onMouseOut={()=> noHoverEffect(4, 2)}>
                            <span className="child-link">Disfrútalos</span>
                            <div id="child-line-4" className="child-line"></div>
                        </div>
                    </div>
                </section>
                <section id="element-with-face-in-5" className="element-with-face-in" tabIndex={5} onMouseOver={() => hoverEffect(5, 1)} onMouseOut={()=> noHoverEffect(5, 1)}>
                    <div className="container-for-elements-with-face-in">
                        <div id="container-title-5" className="container-title">
                            <span className="child-title">Postres</span>
                        </div>
                        <div id="container-link-5" className="container-link" onMouseOver={() => hoverEffect(5, 2)} onMouseOut={()=> noHoverEffect(5, 2)}>
                            <span className="child-link">Disfrútalos</span>
                            <div id="child-line-5" className="child-line"></div>
                        </div>
                    </div>
                </section>
            </article>
            <article className="fade-in">
                <section id="element-with-face-in-6" className="element-with-face-in" tabIndex={6} onMouseOver={() => hoverEffect(6, 1)} onMouseOut={()=> noHoverEffect(6, 1)}>
                    <div className="container-for-elements-with-face-in">
                        <div id="container-title-6" className="container-title">
                            <span className="child-title">Destilados</span>
                        </div>
                        <div id="container-link-6" className="container-link" onMouseOver={() => hoverEffect(6, 2)} onMouseOut={()=> noHoverEffect(6, 2)}>
                            <span className="child-link">Disfrútalos</span>
                            <div id="child-line-6" className="child-line"></div>
                        </div>
                    </div>
                </section>
            </article>
            <article className="fade-in">
                <section id="element-with-face-in-7" className="element-with-face-in" tabIndex={7} onMouseOver={() => hoverEffect(7, 1)} onMouseOut={()=> noHoverEffect(7, 1)}>
                    <div className="container-for-elements-with-face-in">
                        <div id="container-title-7" className="container-title">
                            <span className="child-title">Bebidas</span>
                        </div>
                        <div id="container-link-7" className="container-link" onMouseOver={() => hoverEffect(7, 2)} onMouseOut={()=> noHoverEffect(7, 2)}>
                            <span className="child-link">Disfrútalos</span>
                            <div id="child-line-7" className="child-line"></div>
                        </div>
                    </div>
                </section>
                <section id="element-with-face-in-8" className="element-with-face-in" tabIndex={8} onMouseOver={() => hoverEffect(8, 1)} onMouseOut={()=> noHoverEffect(8, 1)}>
                    <div className="container-for-elements-with-face-in">
                        <div id="container-title-8" className="container-title">
                            <span className="child-title">Vinos</span>
                        </div>
                        <div id="container-link-8" className="container-link" onMouseOver={() => hoverEffect(8, 2)} onMouseOut={()=> noHoverEffect(8, 2)}>
                            <span className="child-link">Disfrútalos</span>
                            <div id="child-line-8" className="child-line"></div>
                        </div>
                    </div>
                </section>
            </article>
            <article className="fade-in">
                <section id="element-with-face-in-9" className="element-with-face-in" tabIndex={9} onMouseOver={() => hoverEffect(9, 1)} onMouseOut={()=> noHoverEffect(9, 1)}>
                    <div className="container-for-elements-with-face-in">
                        <div id="container-title-9" className="container-title">
                            <span className="child-title">V. Copeo</span>
                        </div>
                        <div id="container-link-9" className="container-link" onMouseOver={() => hoverEffect(9, 2)} onMouseOut={()=> noHoverEffect(9, 2)}>
                            <span className="child-link">Disfrútalos</span>
                            <div id="child-line-9" className="child-line"></div>
                        </div>
                    </div>
                </section>
            </article>
        </div>
    )
}

export default FoodMenu
