import React, { useEffect, useRef, useState } from 'react';
import {
    ContainerWineChild,
    ContainerWineWithPriceChild,
    ContainerWineImage,
    WineImage,
    FlagImage,
    ContainerWineText,
    ContainerWineWithPriceText,
    ContainerWineTextChildForNameAndFlag,
    ContainerWineTextChild,
    ContainerCircleForElementTheWine,
    CircleForElementTheWine,
    ContainerWineChildTextTheWineAndName,
    ContainerWineChildTextTheWine,
    ContainerWineWithPriceParameterNameBold,
    WineParameterNameBold,
    WineTitle,
    ContainerButtomForViewMoreAndLess,
    ContainerFormObjectChildButton,
    ModalHeader,
    ModalHeaderText,
    ModalContent,
    CloseModalButtom
} from '../../js/Styles'; // Ajusta la ruta según tu estructura

import Modal from 'react-modal'; // Importa react-modal

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: '450px',
        height: '90%',
        padding: '0px',
        fontFamily: '"Monserrat", sans-serif',
        overflow: 'none'
    },
};

function WineWithPrice({wine}) {

    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para mostrar/ocultar el modal
    const [show_more_or_less, setShowMoreOrLess] = useState(false); // Estado para mostrar menos o más parametros del vino

    const clickButtonWine = () => {
        if(show_more_or_less){
            console.log("mostrar menos");
            setShowMoreOrLess(false);
        }
        else{
            console.log("mostrar más");
            setShowMoreOrLess(true);
        }
    }
    
    // Manejador de eventos cuando se selecciona un vino
    const handleSelectWine = (event) => {
        setIsModalOpen(true);
    };
    

    // Manejador para cerrar el modal
    const closeModal = () => {
        setIsModalOpen(false);
    };
    
    return (
        <>
            <ContainerWineWithPriceChild>

                {
                    wine.imagen === null ?
                    <></> :
                    <ContainerWineImage onClick={()=> handleSelectWine()}>
                        <WineImage src={wine.imagen} />
                    </ContainerWineImage>
                }
                <ContainerWineWithPriceText>
                    <ContainerWineTextChildForNameAndFlag>

                        <ContainerWineChildTextTheWineAndName>

                            <ContainerWineWithPriceParameterNameBold>
                                <WineParameterNameBold><WineTitle>{wine.nombre}</WineTitle></WineParameterNameBold>
                                {
                                    wine.precio_bot === null ?
                                    <></> :
                                    <WineParameterNameBold><WineTitle>${wine.precio_bot}</WineTitle></WineParameterNameBold>
                                }
                            </ContainerWineWithPriceParameterNameBold>
                            
                            <ContainerCircleForElementTheWine>
                                <FlagImage src={wine.imagen_bandera_pais} />
                            </ContainerCircleForElementTheWine>
                        </ContainerWineChildTextTheWineAndName>
                    
                    </ContainerWineTextChildForNameAndFlag>
                    <ContainerWineTextChild>

                        <ContainerWineChildTextTheWine>
                            <ContainerCircleForElementTheWine>
                                <CircleForElementTheWine />
                            </ContainerCircleForElementTheWine>
                            
                            <WineParameterNameBold>Variedad: <WineTitle>{wine.variedad}</WineTitle></WineParameterNameBold>
                        </ContainerWineChildTextTheWine>

                        <ContainerWineChildTextTheWine>
                            <ContainerCircleForElementTheWine>
                                <CircleForElementTheWine />
                            </ContainerCircleForElementTheWine>
                            
                            <WineParameterNameBold>Origen: <WineTitle>{wine.origen_variedad}</WineTitle></WineParameterNameBold>
                        </ContainerWineChildTextTheWine>

                        <ContainerWineChildTextTheWine>
                            <ContainerCircleForElementTheWine>
                                <CircleForElementTheWine />
                            </ContainerCircleForElementTheWine>
                            
                            <WineParameterNameBold>Crianza: <WineTitle>{wine.crianza}</WineTitle></WineParameterNameBold>
                        </ContainerWineChildTextTheWine>
                        {
                            show_more_or_less ?
                            <>
                                <ContainerWineChildTextTheWine>
                                    <ContainerCircleForElementTheWine>
                                        <CircleForElementTheWine />
                                    </ContainerCircleForElementTheWine>
                                    
                                    <WineParameterNameBold>Temperatura de servicio: <WineTitle>{wine.temperatura_servicio}</WineTitle></WineParameterNameBold>
                                </ContainerWineChildTextTheWine>

                                <ContainerWineChildTextTheWine>
                                    <ContainerCircleForElementTheWine>
                                        <CircleForElementTheWine />
                                    </ContainerCircleForElementTheWine>
                                    
                                    <WineParameterNameBold>Graducación alcohólica: <WineTitle>{wine.graducacion_alcoholica}</WineTitle></WineParameterNameBold>
                                </ContainerWineChildTextTheWine>

                                <ContainerWineChildTextTheWine>
                                    <ContainerCircleForElementTheWine>
                                        <CircleForElementTheWine />
                                    </ContainerCircleForElementTheWine>
                                    
                                    <WineParameterNameBold>Vista: <WineTitle>{wine.vista}</WineTitle></WineParameterNameBold>
                                </ContainerWineChildTextTheWine>

                                <ContainerWineChildTextTheWine>
                                    <ContainerCircleForElementTheWine>
                                        <CircleForElementTheWine />
                                    </ContainerCircleForElementTheWine>
                                    
                                    <WineParameterNameBold>Nariz: <WineTitle>{wine.nariz}</WineTitle></WineParameterNameBold>
                                </ContainerWineChildTextTheWine>

                                <ContainerWineChildTextTheWine>
                                    <ContainerCircleForElementTheWine>
                                        <CircleForElementTheWine />
                                    </ContainerCircleForElementTheWine>
                                    
                                    <WineParameterNameBold>Boca: <WineTitle>{wine.boca}</WineTitle></WineParameterNameBold>
                                </ContainerWineChildTextTheWine>

                                <ContainerWineChildTextTheWine>
                                    <ContainerCircleForElementTheWine>
                                        <CircleForElementTheWine />
                                    </ContainerCircleForElementTheWine>
                                    
                                    <WineParameterNameBold>Maridaje: <WineTitle>{wine.maridaje}</WineTitle></WineParameterNameBold>
                                </ContainerWineChildTextTheWine>
                            </>
                            : <></>

                        }

                        <ContainerButtomForViewMoreAndLess>
                            <ContainerButtomForViewMoreAndLess>
                                <ContainerFormObjectChildButton onClick={() => clickButtonWine()}>{show_more_or_less ? "Ver menos" : "Ver más"}</ContainerFormObjectChildButton>
                            </ContainerButtomForViewMoreAndLess>
                        </ContainerButtomForViewMoreAndLess>
                        <br/>
                        <br/>
                    </ContainerWineTextChild>
                </ContainerWineWithPriceText>
            </ContainerWineWithPriceChild>
            <Modal
                style={customStyles}
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                ariaHideApp={false} 
            >
                {isModalOpen && (
                    <ModalContent>
                        {/* Agrega más detalles del evento aquí */}
                        <ModalHeader>
                            <ModalHeaderText>{wine.tipo}</ModalHeaderText>
                            <CloseModalButtom onClick={closeModal}>X</CloseModalButtom>
                        </ModalHeader>
                        <ContainerWineChild>
                            <ContainerWineImage>
                                <WineImage src={wine.imagen} />
                            </ContainerWineImage>
                            <ContainerWineText>
                                <ContainerWineTextChildForNameAndFlag>
                                    <ContainerWineChildTextTheWineAndName>

                                        <WineParameterNameBold><WineTitle>{wine.nombre}</WineTitle></WineParameterNameBold>
                                        <ContainerCircleForElementTheWine>
                                            <FlagImage src={wine.imagen_bandera_pais} />
                                        </ContainerCircleForElementTheWine>
                                    </ContainerWineChildTextTheWineAndName>
                                </ContainerWineTextChildForNameAndFlag>
                                <ContainerWineTextChild>

                                    <ContainerWineChildTextTheWine>
                                        <ContainerCircleForElementTheWine>
                                            <CircleForElementTheWine />
                                        </ContainerCircleForElementTheWine>
                                        
                                        <WineParameterNameBold>Variedad: <WineTitle>{wine.variedad}</WineTitle></WineParameterNameBold>
                                    </ContainerWineChildTextTheWine>

                                    <ContainerWineChildTextTheWine>
                                        <ContainerCircleForElementTheWine>
                                            <CircleForElementTheWine />
                                        </ContainerCircleForElementTheWine>
                                        
                                        <WineParameterNameBold>Origen: <WineTitle>{wine.origen_variedad}</WineTitle></WineParameterNameBold>
                                    </ContainerWineChildTextTheWine>

                                    <ContainerWineChildTextTheWine>
                                        <ContainerCircleForElementTheWine>
                                            <CircleForElementTheWine />
                                        </ContainerCircleForElementTheWine>
                                        
                                        <WineParameterNameBold>Crianza: <WineTitle>{wine.crianza}</WineTitle></WineParameterNameBold>
                                    </ContainerWineChildTextTheWine>

                                    <ContainerWineChildTextTheWine>
                                        <ContainerCircleForElementTheWine>
                                            <CircleForElementTheWine />
                                        </ContainerCircleForElementTheWine>
                                        
                                        <WineParameterNameBold>Temperatura de servicio: <WineTitle>{wine.temperatura_servicio}</WineTitle></WineParameterNameBold>
                                    </ContainerWineChildTextTheWine>

                                    <ContainerWineChildTextTheWine>
                                        <ContainerCircleForElementTheWine>
                                            <CircleForElementTheWine />
                                        </ContainerCircleForElementTheWine>
                                        
                                        <WineParameterNameBold>Graducación alcohólica: <WineTitle>{wine.graducacion_alcoholica}</WineTitle></WineParameterNameBold>
                                    </ContainerWineChildTextTheWine>

                                    <ContainerWineChildTextTheWine>
                                        <ContainerCircleForElementTheWine>
                                            <CircleForElementTheWine />
                                        </ContainerCircleForElementTheWine>
                                        
                                        <WineParameterNameBold>Vista: <WineTitle>{wine.vista}</WineTitle></WineParameterNameBold>
                                    </ContainerWineChildTextTheWine>

                                    <ContainerWineChildTextTheWine>
                                        <ContainerCircleForElementTheWine>
                                            <CircleForElementTheWine />
                                        </ContainerCircleForElementTheWine>
                                        
                                        <WineParameterNameBold>Nariz: <WineTitle>{wine.nariz}</WineTitle></WineParameterNameBold>
                                    </ContainerWineChildTextTheWine>

                                    <ContainerWineChildTextTheWine>
                                        <ContainerCircleForElementTheWine>
                                            <CircleForElementTheWine />
                                        </ContainerCircleForElementTheWine>
                                        
                                        <WineParameterNameBold>Boca: <WineTitle>{wine.boca}</WineTitle></WineParameterNameBold>
                                    </ContainerWineChildTextTheWine>

                                    <ContainerWineChildTextTheWine>
                                        <ContainerCircleForElementTheWine>
                                            <CircleForElementTheWine />
                                        </ContainerCircleForElementTheWine>
                                        
                                        <WineParameterNameBold>Maridaje: <WineTitle>{wine.maridaje}</WineTitle></WineParameterNameBold>
                                    </ContainerWineChildTextTheWine>
                                    <br/>
                                    <br/>
                                </ContainerWineTextChild>
                            </ContainerWineText>
                        </ContainerWineChild>
                    </ModalContent>
                )}
            </Modal>
        </>
    )
}

export default WineWithPrice